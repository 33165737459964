<template>
  <div
    style="font-weight: 500"
    class="table-text"
  >
    {{ author }}
  </div>
</template>

<script>
export default {
  props: {
    author: String,
  },
}
</script>