<template>
  <div class="table-text">
    {{ truncated }}
  </div>
</template>

<script>
export default {
  props: {
    text: String,
  },
  computed: {
    truncated () {
      const maxLength = 250
      const tooLong = this.text.length > maxLength

      if (tooLong) {
        return this.text.slice(0, maxLength) + '…'
      } else {
        return this.text
      }
    },
  },
}
</script>