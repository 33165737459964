<template>
  <SearchInput
    v-model="search"
    placeholder="Search sites"
    style="box-shadow: var(--element-box-shadow) var(--color-tan-300)"
  />
  <div
    class="flex-row-start"
    style="margin-top: var(--spacing-xl); cursor: pointer"
    @click="toggleSelectAll"
   >
    <CheckBox
      :model-value="isSelectAll"
      @update:modelValue="toggleSelectAll"
      @click.stop
    />
    <div style="margin-left: var(--spacing-md)" class="website-text">Select All</div>
  </div>
  <div
    v-for="(website, i) in displayedWebsites"
    :key="website.name"
    class="flex-row-start"
    style="margin-top: var(--spacing-xl); cursor: pointer"
    @click="toggle(i, website)"
  >
    <CheckBox
      :model-value="website.active"
      @update:modelValue="toggle(i, website)"
      @click.stop
    />
    <WebsiteIcon :website="website.name" style="margin: 0 var(--spacing-md)" />
    <div class="website-text">{{ website.label }}</div>
  </div>
  <GhostButton class="see-more-button" v-if="filteredWebsites.length > 5" @click="toggleShowAll">
    {{ showAll ? 'See Less' : 'See More' }}
  </GhostButton>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import WebsiteIcon from '@/components/WebsiteIcon'
import SearchInput from '../input/SearchInput.vue'
import GhostButton from './GhostButton.vue'
import CheckBox from './CheckBox.vue'

const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    WebsiteIcon,
    SearchInput,
    CheckBox,
    GhostButton
  },
  data () {
    return {
      search: '',
      showAll: false,
    }
  },
  emits: ['update:activeCount'],
  mounted () {
    // Emit the initial active count when the component is mounted
    this.$emit('update:activeCount', this.activeCount)
  },
  computed: {
    ...mapGetters(['websites']),
    filteredWebsites () { // Add this computed property
      if (!this.search) {
        return [...this.websites].sort((a, b) => b.active - a.active)
      }
      const searchLower = this.search.toLowerCase()
      return [...this.websites]
        .filter(website => website.name.toLowerCase().includes(searchLower))
        .sort((a, b) => b.active - a.active)
    },
    activeCount () { // calculate active websites
      return this.filteredWebsites.filter(website => website.active).length
    },
    displayedWebsites () {
      return this.showAll ? this.filteredWebsites : this.filteredWebsites.slice(0, 5)
    },
    isSelectAll () {
      return this.websites.every(type => type.active)
    }
  },
  methods: {
    ...mapMutations(['setWebsiteActive']),
    toggle (i, website) {
      const index = this.websites.findIndex(w => w.name === website.name)
      this.setWebsiteActive({ i: index, val: !website.active })
      this.search = ''
    },
    toggleShowAll () { // New method to toggle the showAll state
      this.showAll = !this.showAll
    },
    toggleSelectAll () {
      const newValue = !this.isSelectAll
      this.websites.forEach((website, index) => {
        this.setWebsiteActive({ i: index, val: newValue })
      })
    }
  },
  watch: {
    activeCount (newCount) {
      this.$emit('update:activeCount', newCount)
    }
  },
}
</script>

<style scoped lang="scss">
.website-text {
  font-size: var(--fs-2);
  line-height: var(--fs-5);
  font-weight: 400;
}

.see-more-button {
  margin-top: 0.214rem;
  margin-left: calc(-1 * var(--spacing-xxl));
  font-size: var(--fs-2);
}
</style>
