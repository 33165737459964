<template>
    <div
      class="pill"
      @click="$emit('pill-click'), clicked()"
      :class=" {
        'hover': clickable,
      }"
    >
      <div
        class="pill-body"
        v-if="title !== ''"
      >
        <div class="text-body-2 text-bold pill-title-text">
          {{ title }}
        </div>
        <div
          v-if="body !== ''"
          class="text-body-4 pill-body-text"
        >
        <img v-if="doCopy" :src="copyIconComp" class="copy-button" :style='{height: `20px`, width:`20px` }'/>
        <span :style="{ color: highlighted ? 'var(--color-purple)': ''}">
          {{ highlighted ? 'Copied!' : body }}
        </span>
        </div>
        <hr v-if="hasFooterSlot">
        <slot name="footer" />
      </div>
      <slot
        name="alt"
        v-else
      />
      <IconTool
        v-if="tool !== ''"
        :tool="tool"
        :size="100"
        class="icon"
        iconColor='dark'
      />
    </div>
  </template>

<script>
import CopyIcon from '@/assets/copy.svg'
import IconTool from '@/components/input/tool-picker/IconTool.vue'

export default {
  components: {
    IconTool,
  },
  props: {
    clickable: {
      type: Boolean,
      default: true,
    },
    tool: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: ''
    },
    doCopy: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      highlighted: false,
    }
  },
  computed: {
    hasFooterSlot () {
      return !!this.$slots.footer
    },
    copyIconComp () {
      return CopyIcon
    },
  },
  methods: {
    clicked () {
      if (this.doCopy) {
        navigator.clipboard.writeText(this.body)
          .then(() => {
            this.highlighted = true
            setTimeout(() => {
              this.highlighted = false
            }, 3000)
          })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
$inner-padding: 25px;

.cursor-on-hover:hover {
  cursor: pointer;
}

.copy-button {
  margin-right: 10px;
}

.pill {
  position: relative;
  background: var(--color-white);
  color: var(--color-red);
  padding: 20px;
  margin-bottom: $inner-padding;
  border-radius: var(--border-radius-3);
  outline: 0.05px solid black;
  justify-content: space-between;
  margin-bottom: 12px;
  display: flex;
  border-left: 5px solid var(--color-white);
}

.icon {
  align-self: center;
  justify-self: end;
}

.pill-title-text {
  line-height: 23px;
  margin-bottom: 12px;
}

.pill-body {
  color: var(--color-black);
  display: flex;
  flex-direction: column;
}

hr {
  border: 0;
  height: 1px;
  background-color: var(--color-dividers);
  width: calc(100% + 2 * #{$inner-padding});
  margin-top: $inner-padding;
  margin-bottom: $inner-padding;
  margin-left: -$inner-padding;
}

.pill-body-text {
  padding-right: 25px;
}

.hover {
  &:hover, &.selected {
    cursor: pointer;
    border-left: 5px solid var(--color-purple);
  }
}
</style>
