<template>
  <div>
    <div
      v-if="label"
      :class="['label', `label-${mode}-mode`, { [`is-disabled-${mode}-label`]: disabled }]"
    >
      {{ label }}
    </div>
    <div
      :class="[
      'container',
      `container-${mode}-mode`,
      {
        'has-value': hasValue,
        'is-disabled': disabled,
        'has-error': hasError,
        'no-corner': disableCornerStyle
      }]"
    :style="{ width: width }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasValue: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    disableCornerStyle: {
      type: Boolean,
      default: false
    },
    label: String,
    mode: String,
    width: {
      type: String,
      default: '100%'
    },
    disabled: Boolean,
  },
}
</script>

<style scoped>
.label {
  text-align: left;
  font-size: var(--fs-1) !important;
  font-weight: 500 !important;
  margin-bottom: var(--spacing-sm);
  font: var(--primary-font);
}

.label-light-mode {
  color: var(--color-tan-950);
}

.label-dark-mode {
  color: var(--color-tan-100);
}

.container {
  position: relative;
  padding: 9px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-light-mode {
  border: 1px solid var(--color-tan-600); /* Initial border color */
  background-color: var(--color-white);
}

.container-dark-mode {
  border: 1px solid var(--color-tan-400); /* Initial border color */
  background-color: var(--color-primary-950);
}

.container:not(.no-corner)::after {
  content: "";
  position: absolute;
  bottom: -3.5px;
  right: -3.5px;
  width: 7px;
  height: 7px;
  border: 1px solid var(--color-tan-600);
  box-sizing: border-box;
  background-color: var(--color-white);
}

.container-light-mode:hover {
  border-color: var(--color-primary-600);
}

.container-dark-mode:hover {
  border-color: var(--color-primary-300);
}

.container-light-mode:hover::after {
  border-color: var(--color-primary-600);
  background-color: var(--color-primary-600);
}

.container-dark-mode:hover::after {
  border-color: var(--color-primary-300);
  background-color: var(--color-primary-300);
}

 /* when focused */
.container-light-mode:focus-within {
  border-color: var(--color-primary-600);
}

.container-dark-mode:focus-within {
  border-color: var(--color-primary-300);
}

.container-light-mode:focus-within::after {
  border-color: var(--color-primary-600);
  background-color: var(--color-primary-600);
}

.container-dark-mode:focus-within::after {
  border-color: var(--color-primary-300);
  background-color: var(--color-primary-300);
}

/* when has value and not focused */
.container-light-mode.has-value:not(:focus-within) {
  border-color: var(--color-tan-950);
}

.container-light-mode.has-value:not(:focus-within)::after {
  border-color: var(--color-tan-950);
  background-color: var(--color-tan-950);
}

.container-dark-mode.has-value:not(:focus-within) {
  border-color: var(--color-tan-100);
}

.container-dark-mode.has-value:not(:focus-within)::after {
  border-color: var(--color-tan-100);
  background-color: var(--color-tan-100);
}

/* when there is an error */
.container-light-mode.has-error {
  border-color: var(--color-red-700) !important;
}

.container-light-mode.has-error::after {
  border-color: var(--color-red-700) !important;
  background-color: var(--color-red-700) !important;
}

.container-dark-mode.has-error {
  border-color: var(--color-red-400) !important;
}

.container-dark-mode.has-error::after {
  border-color: var(--color-red-400) !important;
  background-color: var(--color-red-400) !important;
}

 /* when disabled */
.is-disabled-light-label {
  color: var(--color-tan-400);
}

.is-disabled-dark-label {
  color: var(--color-tan-700);
}

.container-light-mode.is-disabled {
  border-color: var(--color-tan-400);
  cursor: not-allowed;
}

.container-dark-mode.is-disabled {
  border-color: var(--color-tan-700);
  cursor: not-allowed;
}

.is-disabled::after  {
  display: none;
}
</style>