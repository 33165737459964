<template>
  <div class="source-cell">
    <WebsiteIcon :website="value" />
    <div class="table-text">
      {{ siteLabel }}
    </div>
  </div>
</template>

<script>
import { siteLabel } from '../helpers/crawlRequests'
import WebsiteIcon from './WebsiteIcon.vue'

export default {
  components: {
    WebsiteIcon,
  },
  props: {
    value: String,
    label: String,
  },
  computed: {
    siteLabel () {
      return this.label ?? siteLabel(this.value)
    },
  }
}
</script>

<style scoped lang="scss">
.source-cell {
  display: flex;
  align-content: center;
  gap: var(--spacing-xs);
}
</style>