<template>
  <div class="search-body">
    <div
      class="search-control-container"
      :style="{
        paddingTop: scrollPosition ? '0' : 'var(--spacing-xxl)',
        top: scrollPosition ? 'var(--spacing-xxl)' : '0',
      }"
    >
      <SearchControl
        style="padding: 0 var(--spacing-huge)"
        class="search-control"
        v-model:displayType="displayType"
        :numResults="numResults"
        v-model:displayMedia="displayMedia"
        v-model:blurThumbnails="blurThumbnails"
        v-model:sortBy="sortBy"
        @doExport="doExport"
        @share="share"
        @summary="showSummary"
      />
    </div>
    <div
      class="search-results"
      :style="{ marginTop: `${196 - scrollPosition}px`}"
    >
      <BodyError v-if="error" :error="error" />
      <template v-else>
        <SearchTable
          v-if="displayType === 'table'"
          :resultsData="resultsData"
          :loading="loading"
          :displayMedia="displayMedia"
          :blurThumbnails="blurThumbnails"
        />
        <SearchCards
          v-else-if="displayType === 'cards'"
          :resultsData="resultsData"
          :loading="loading"
        />
        <div v-else-if="displayType === 'media'">Media view</div>
      </template>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { normaliseResults } from '@/helpers/normalise-results'
import SearchControl from '../search/SearchControl.vue'
import BodyError from './BodyError'
import SearchTable from '../search/SearchTable.vue'
import SearchCards from '../search/SearchCards.vue'

const { mapGetters } = createNamespacedHelpers('searchSettings')


export default {
  components: {
    SearchControl,
    BodyError,
    SearchTable,
    SearchCards,
  },
  props: {
    lastSearchSettings: Object,
    results: Array,
    resultsAvailable: Boolean,
    loading: Boolean,
    error: Error
  },
  data () {
    return {
      displayType: 'table',
      displayMedia: true,
      blurThumbnails: true,
      sortBy: 'date',
    }
  },
  computed: {
    ...mapGetters(['scrollPosition']),
    resultsData () {
      return normaliseResults(this.results)?.map(result => ({
        date: result.created_at,
        type: result.type,
        text: result.text,
        site: result.website,
        channel: result.context,
        author: result.author,
        engagement: result.engagement,
        mediatype: result.mimetype,
        media: {
          thumbnail_url: result.thumbnail_url,
          media_url: result.media_url,
          mimetype: result.mimetype,
        },
        link: result.link,
      }))
    },
    numResults () {
      return this.$store.getters['searchSettings/numberOfResults']
    },
  },
  methods: {
    doExport (type) {
      console.log('export as', type)
    },
    share () {
      console.log('clicked share button')
    },
    showSummary () {
      console.log('show "Search summary" modal')
    },
  },
}
</script>

<style scoped lang="scss">
.search-body {
  display: flex;
  flex-direction: column;
}

.search-control-container {
  position: sticky;
  z-index: 1;
  background-color: var(--color-white);
}

.search-results {
  flex: 1;
  padding: var(--spacing-huge);
}

.search-control {
  margin-bottom: var(--spacing-xl);
}
</style>
