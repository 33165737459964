<template>
  <router-link
    @click="$emit('click')"
    :to="href ?? { name: tool, query: this.$route.query }"
    :href="href"
    :target="href ? '_blank' : undefined"
    class="tool-button"
  >
    <div
      class="flex-row-start"
      style="align-items: flex-start; padding: var(--spacing-xxl)"
      :class="{ 'active-tool-button': isActive }"
    >
      <IconTool
        :tool="tool"
        :size='50'
        iconColor='dark'
        style="margin-top: -12px"
      />
      <div>
        <div class="name">
          {{ $t(`nav.${tool}`) }}
        </div>
        <div class="description">
          {{ $t(`toolDescription.${tool}`) }}
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import IconTool from './IconTool'

export default {
  components: {
    IconTool
  },
  props: {
    tool: String,
    href: String,
    isActive: Boolean
  },
}
</script>

<style scoped lang="scss">
.tool-button {
  cursor: pointer;
  background-color: var(--color-white);
  width: 26.2rem;
}

.active-tool-button {
  background-color: var(--color-primary-100);
  margin-bottom: var(--spacing-xxl);
  border-left: var(--border-width-2) solid var(--color-primary-600);
}

.name {
  font-size: var(--fs-2);
  font-weight: 500;
  color: var(--color-tan-950);
  font-family: var(--primary-font);
  line-height: var(--fs-5);
}

.description {
  font-size: var(--fs-1);
  font-weight: 300;
  color: var(--color-tan-950);
  font-family: var(--primary-font);
  line-height: var(--fs-4);
}
</style>
