<template>
  <BaseModal
    :model-value="value"
    @update:model-value="$emit('input', $event), $emit('update:value', $event)"
    :title="$t('toolPicker.modalTitle')"
    maxWidth="800px"
  >
    <div class="subtitle">
      {{ $t("toolPicker.modalSubtitle") }}
    </div>
    <ButtonToolPicker
      :tool="currentTool"
      :isActive="true"
      @click="$emit('input', false), $emit('update:value', false)"
    />
    <div class="tool-buttons">
      <ButtonToolPicker
        v-for="tool in availableTools"
        @click="$emit('input', false), $emit('update:value', false)"
        :key="tool.name"
        :href="tool.href"
        :tool="tool.name"
      />
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import ButtonToolPicker from './ButtonToolPicker'
import { POPUP_TOOLS } from '@/helpers/searchQuery'

export default {
  components: {
    BaseModal,
    ButtonToolPicker
  },
  props: {
    value: Boolean
  },
  computed: {
    currentTool () {
      return this.$route.name
    },
    availableTools  () {
      return POPUP_TOOLS.filter(tool => tool !== this.currentTool).map(tool => ({
        name: tool,
        href: this.getToolHref(tool)
      }))
    }
  },
  methods: {
    getToolHref (tool) {
      if (tool === 'dashboard') {
        return 'https://smat-cloud.kb.us-east1.gcp.elastic-cloud.com:9243'
      } else if (tool === 'network') {
        return 'https://graph.openmeasures.io'
      }
      return undefined
    }
  }
}
</script>

<style scoped lang="scss">
.subtitle {
  font-family: var(--primary-font);
  color: var(--color-tan-950);
  font-size: var(--fs-2);
  font-weight: 400;
  margin-bottom: var(--spacing-xxl);
}

.tool-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacing-xxl);
}

</style>
