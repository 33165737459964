<template>
  <div class="search-control">
    <div class="left-part">
      <!--TODO: this toggle can have a shared base component with the one in TypeTabs.vue-->
      <ToggleButton
        :value="displayType"
        :options="displayOptions"
        @click="handleDisplayMode"
      />
      {{ numResults }} results
    </div>
    <div class="right-part">
      <LabelToggle
        :model-value="displayMedia"
        @update:model-value="$emit('update:displayMedia', $event)"
        label="Show Media"
      />
      <LabelToggle
        :model-value="blurThumbnails"
        @update:model-value="$emit('update:blurThumbnails', $event)"
        label="Blur thumbnails"
      />
      <!--TODO: there's another dropdown like this in LanguagePickerDropdown.vue-->
      <!--<q-select
        label="Sort by: Date"
        :model-value="sortBy"
        @update:model-value="$emit('update:sortBy', $event)"
        :options="['date', 'potatoes', 'apples']"
      />-->
      <Select
        :label="'Export'"
        @select="handleExportSelect"
        :options="exportOptions"
        size="sm"
      />
      <MiniButtonContainer class="flex-row-center" style="padding: 0.786rem" @click="$emit('share')">
        <ShareIcon class="share-icon" />
      </MiniButtonContainer>
      <Button @click="$emit('summary')">
        See summary
      </Button>
    </div>
  </div>
</template>

<script>
import ToggleButton from '../input/ToggleButton.vue'
import LabelToggle from '../input/LabelToggle.vue'
import Select from '../input/Select.vue'
import MiniButtonContainer from '../input/MiniButtonContainer.vue'

import Button from '../input/Button.vue'

import TableIcon from '../../assets/svg/color/table.svg?component'
import GridIcon from '../../assets/svg/color/grid.svg?component'
import MediaIcon from '../../assets/svg/color/media.svg?component'
import ShareIcon from '../../assets/svg/color/share.svg?component'

export default {
  data () {
    return {
      displayOptions: [
        {
          value: 'table',
          component: TableIcon,
        },
        {
          value: 'cards',
          component: GridIcon,
        },
        {
          value: 'media',
          component: MediaIcon,
        }
      ],
      exportOptions: [
        { label: 'CSV', value: 'csv' },
        { label: 'JSON', value: 'json' }
      ],
    }
  },
  components: {
    ToggleButton,
    LabelToggle,
    Select,
    Button,
    MiniButtonContainer,
    ShareIcon
  },
  props: {
    displayType: String,
    numResults: Number,
    displayMedia: Boolean,
    blurThumbnails: Boolean,
    sortBy: String,
  },
  methods: {
    handleExportSelect (option) {
      this.$emit('doExport', option.value)
    },
    handleDisplayMode (mode) {
      this.$emit('update:displayType', mode)
    }
  }
}
</script>

<style scoped lang="scss">
.search-control {
  display: flex;
  gap: var(--spacing-1);
}

.left-part {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxl);
}

.right-part {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: var(--spacing-xxl);
}

.share-icon {
  height: 16px;
  width: 16px
}
</style>