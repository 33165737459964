<template>
  <div>
    <video
      :src="src"
      ref="video"
      @click="$refs.video.paused ? play() : pause()"
      @loadedmetadata="totalTime = $event.target.duration"
      @timeupdate="currentTime = $event.target.currentTime"
    />
    <div class="details">
      <div class="info">
        <div class="title">
          {{ row.text }}
        </div>
        <div class="stats">
          <EngagementRow :engagement="row.engagement"/>
          <WebsiteIcon :website="row.site.name"/>
        </div>
      </div>
      <div class="controls">
        <div class="buttons">
          <GhostButton @click="mute" variant="secondary" no-padding>
            <SoundIcon/>
          </GhostButton>
          <GhostButton @click="rewind" variant="secondary" no-padding>
            <RewindIcon/>
          </GhostButton>
          <GhostButton @click="stop" variant="secondary" no-padding>
            <StopIcon/>
          </GhostButton>
          <GhostButton @click="pause" variant="secondary" no-padding>
            <PauseIcon/>
          </GhostButton>
          <GhostButton @click="play" variant="secondary" no-padding>
            <PlayIcon/>
          </GhostButton>
          <GhostButton @click="forward" variant="secondary" no-padding>
            <FastForwardIcon/>
          </GhostButton>
        </div>
        <div class="scrub" @click="scrub($event)">
          <div class="progress-bar">
            <div
              class="bar"
              :style="{ width: progress * 100 + '%' }"
            />
            <div class="dot"/>
          </div>
        </div>
        <div class="time-and-download">
          <div class="time">
            <div class="current-time">
              {{ currentMinutes }}:{{ currentSeconds }}
            </div>
            /
            <span class="total-time">
              {{ totalMinutes }}:{{ totalSeconds }}
            </span>
          </div>
          <GhostButton @click="download" variant="secondary" no-padding>
            <DownloadIcon/>
          </GhostButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EngagementRow from './EngagementRow.vue'
import WebsiteIcon from '../WebsiteIcon.vue'
import GhostButton from '../input/GhostButton.vue'
import SoundIcon from '../../assets/svg/color/sound.svg?component'
import RewindIcon from '../../assets/svg/color/rewind.svg?component'
import StopIcon from '../../assets/svg/color/stop.svg?component'
import PauseIcon from '../../assets/svg/color/pause.svg?component'
import PlayIcon from '../../assets/svg/color/play.svg?component'
import FastForwardIcon from '../../assets/svg/color/fastForward.svg?component'
import DownloadIcon from '../../assets/svg/color/download.svg?component'

export default {
  components: {
    EngagementRow,
    WebsiteIcon,
    GhostButton,
    SoundIcon,
    RewindIcon,
    StopIcon,
    PauseIcon,
    PlayIcon,
    FastForwardIcon,
    DownloadIcon,
  },
  props: {
    src: String,
    cell: Object,
    row: Object,
  },
  data () {
    return {
      currentTime: 0,
      totalTime: 0,
    }
  },
  computed: {
    currentMinutes () {
      return Math.floor(this.currentTime / 60)
    },
    currentSeconds () {
      return String(Math.floor(this.currentTime % 60)).padStart(2, '0')
    },
    totalMinutes () {
      return Math.floor(this.totalTime / 60)
    },
    totalSeconds () {
      return String(Math.floor(this.totalTime % 60)).padStart(2, '0')
    },
    progress () {
      return this.currentTime / this.totalTime
    }
  },
  methods: {
    mute () {
      this.$refs.video.muted = !this.$refs.video.muted
    },
    rewind () {
      this.$refs.video.currentTime -= 10
    },
    stop () {
      this.$refs.video.pause()
      this.$refs.video.currentTime = 0
    },
    pause () {
      this.$refs.video.pause()
    },
    play () {
      this.$refs.video.play()
    },
    forward () {
      this.$refs.video.currentTime += 30
    },
    scrub (event) {
      const scrubTime = event.offsetX / event.target.offsetWidth * this.totalTime
      this.$refs.video.currentTime = scrubTime
    },
    download () {
      const a = document.createElement('a')
      a.href = this.src
      a.click()
    }
  },
}
</script>

<style scoped>
.details {
  display: flex;
  padding: var(--spacing-xxl) var(--spacing-huge);
  flex-direction: column;
  gap: var(--spacing-xxl);
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-huge);
}

.title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.stats {
  display: flex;
  align-items: center;
  gap: var(--spacing-huge);
}

.controls {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto 1fr auto;
  gap: var(--spacing-huge);
  align-items: center;
}

.buttons {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxl);
}

.scrub {
  height: 100%;
  display: flex;
  cursor: pointer;
}

.progress-bar {
  display: flex;
  align-items: center;
  height: 2px;
  background-color: var(--color-light-border);
  margin: auto;
  width: 100%;
}

.bar {
  height: 2px;
  background-color: var(--color-primary-600);
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--color-primary-600);
}

.time-and-download {
  display: flex;
  gap: var(--spacing-huge);
}

.current-time {
  width: 3rem;
  display: inline-block;
  text-align: end;
}
</style>