<template>
  <div
    class="accordion"
    :class="{ active: isExpanded }"
  >
    <div class="header">
      <div class="flex-row-start">
        <span class="title-text">{{ title }}</span>
        <Badge v-if="badgeCount" style="margin-left: var(--spacing-md)">
          {{ badgeCount }}
        </Badge>
      </div>
      <ExpandButton :isOpen="isExpanded" @click="togglePanel" />
    </div>
    <AnimatedAccordion :isExpanded="isExpanded">
      <slot></slot>
    </AnimatedAccordion>
  </div>
</template>

<script>
import AnimatedAccordion from '@/animations/AnimatedAccordion.vue'
import ExpandButton from './input/ExpandButton.vue'
import Badge from './input/tool-picker/Badge.vue'

export default {
  props: {
    title: String,
    badgeCount: {
      type: [Number, String],
      default: null,
    }
  },
  data () {
    return {
      isExpanded: true,
    }
  },
  methods: {
    togglePanel () {
      // Toggle between showing or hiding the panel
      this.isExpanded = !this.isExpanded
    },
    setStartHeight (el) {
      el.style.height = '0'
    },
    setExpandedHeight (el) {
      el.style.height = el.scrollHeight + 'px'
      el.addEventListener('transitionend', () => {
        el.style.height = '' // Reset to auto for responsiveness
      }, { once: true })
    },
    setCollapsedHeight (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    collapseHeight (el) {
      el.style.height = '0'
    },
  },
  components: {
    ExpandButton,
    Badge,
    AnimatedAccordion
  },
}
</script>

<style scoped>
.accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: background 0.3s;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-text {
  font-size: var(--fs-1);
  line-height: var(--fs-4);
  font-weight: 500;
  color: var(--color-tan-950);
}
</style>