<template>
  <BaseIcon
    name="expand_more"
    class="arrow-icon"
    :size="size"
    :style="{ color }"
    :class="{ rotate: !disabled && rotate }"
  />
</template>

<script>
import BaseIcon from '@/components/BaseIcon'

export default {
  components: {
    BaseIcon
  },
  props: {
    rotate: Boolean,
    disabled: Boolean,
    size: String,
    color: {
      default: 'var(--color-light-text)'
    }
  }
}
</script>

<style scoped lang="scss">
.arrow-icon {
  transition: transform 0.3s;
  justify-self: end;
}

.arrow-icon.rotate {
  transform: rotate(180deg);
}
</style>
