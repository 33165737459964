<template>
  <transition
    name="accordion-transition"
    @before-enter="setStartHeight"
    @enter="setExpandedHeight"
    @before-leave="setCollapsedHeight"
    @leave="collapseHeight"
  >
    <div v-show="isExpanded" class="content">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isExpanded: Boolean
  },
  methods: {
    setStartHeight (el) {
      el.style.height = '0'
    },
    setExpandedHeight (el) {
      el.style.height = el.scrollHeight + 'px'
      el.addEventListener('transitionend', () => {
        el.style.height = '' // Reset to auto for responsiveness
      }, { once: true })
    },
    setCollapsedHeight (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    collapseHeight (el) {
      el.style.height = '0'
    },
  },
}
</script>

<style scoped>
.content {
  overflow: visible;
}

.accordion-transition-enter-active,
.accordion-transition-leave-active {
  transition: height 0.3s ease;
  overflow: hidden;
}

.accordion-transition-enter-from,
.accordion-transition-leave-to {
  height: 0;
}
</style>