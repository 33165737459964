<template>
  <div
    :style="{
      width: `calc(100% - ${sidebarMiniState ? '4.714rem' : '366px'})`
    }"
    class="container flex-row-between"
  >
    <div class="page-number">{{ pageSize * (currentPage - 1) + 1 }} - {{ pageSize * currentPage }} of {{ totalResults }}</div>
    <div class="flex-row-start pagination-numbers">
      <RightArrow
        style="transform: rotate(180deg)"
        class="arrow-icon"
        :class="{ disabled: currentPage === 1 }"
        @click="handlePageChange(currentPage - 1)"
      />
      <div
        v-for="num in pages"
        :key="num"
        :class="['page-number', { active: currentPage === num }]"
        @click="handlePageChange(num)"
      >
        {{ num }}
      </div>
      <RightArrow
        class="arrow-icon"
        :class="{ disabled: currentPage === totalPages }"
        @click="handlePageChange(currentPage + 1)"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import RightArrow from '@/assets/svg/color/arrowRight.svg?component'

const { mapGetters } = createNamespacedHelpers('searchSettings')

export default {
  props: {
    totalPages: String,
    totalResults: String,
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 50
    }
  },
  components: {
    RightArrow
  },
  computed: {
    ...mapGetters(['sidebarMiniState']),
    pages () {
      const pages = []
      if (this.totalPages <= 7) {
        for (let i = 1; i <= this.totalPages; i++) {
          pages.push(i)
        }
      } else {
        if (this.currentPage <= 4) {
          // Show first 5 pages + ... + last 2
          for (let i = 1; i <= 5; i++) pages.push(i)
          pages.push('...')
          pages.push(this.totalPages - 1, this.totalPages)
        } else if (this.currentPage >= this.totalPages - 3) {
          // Show first 2 + ... + last 5 pages
          pages.push(1, 2)
          pages.push('...')
          for (let i = this.totalPages - 4; i <= this.totalPages; i++) pages.push(i)
        } else {
          // Show first 2 + ... + current-1,current,current+1 + ... + last 2
          pages.push(1, 2)
          pages.push('...')
          pages.push(this.currentPage - 1, this.currentPage, this.currentPage + 1)
          pages.push('...')
          pages.push(this.totalPages - 1, this.totalPages)
        }
      }
      return pages
    }
  },
  methods: {
    handlePageChange (page) {
      if (page === '...' || page < 1 || page > this.totalPages) return
      this.$emit('page-change', page)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  position: fixed;
  bottom: 0;
  background-color: var(--color-white);
  opacity: 0.85;
  height: 2.8571rem;
  margin-left: calc(-1 * var(--spacing-huge));
  padding: 0 var(--spacing-xxl);
}

.arrow-icon {
  width: var(--fs-7);
  height: var(--fs-7);
  color: var(--color-tan-950);
  cursor: pointer;
}

.page-number {
  font-size: var(--fs-1);
  font-weight: 500;
  color: var(--color-tan-950);
  padding: 0 var(--spacing-md);
  cursor: pointer;
}

.active {
  color: var(--color-primary-600);
}
</style>