<template>
  <div>
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      :width="width + 'pt'"
      :height="height + 'pt'"
      viewBox="0 0 1211.000000 756.000000"
      preserveAspectRatio="xMidYMid meet"
    >

      <g
        transform="translate(0.000000,756.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
        id="icon"
      >
        <path
          d="M2292 7271 c-97 -34 -180 -120 -208 -219 -46 -158 34 -336 184 -405
47 -22 51 -22 737 -22 l690 0 52 27 c76 40 115 79 154 150 31 58 34 70 34 153
0 71 -4 100 -22 137 -30 66 -92 129 -160 164 l-58 29 -675 2 c-634 3 -678 2
-728 -16z"
        />
        <path
          d="M4624 7281 c-94 -23 -186 -100 -227 -189 -18 -37 -22 -66 -22 -137 0
-83 3 -95 34 -153 39 -71 78 -110 154 -150 l52 -27 690 0 c686 0 690 0 737 22
66 30 130 93 163 160 26 51 29 69 30 148 0 79 -4 97 -28 146 -32 66 -99 132
-165 162 -47 22 -53 22 -717 24 -368 1 -684 -2 -701 -6z"
        />
        <path
          d="M6915 7276 c-92 -29 -159 -90 -204 -186 -37 -78 -36 -191 0 -270 31
-65 93 -131 153 -160 69 -33 143 -40 436 -40 l275 0 145 -253 c80 -138 159
-268 177 -288 18 -19 56 -48 85 -65 49 -27 61 -29 158 -29 98 0 109 2 159 30
68 38 125 102 152 174 26 69 23 195 -5 251 -10 19 -104 185 -210 369 -203 356
-240 407 -331 451 l-50 25 -450 2 c-342 2 -460 -1 -490 -11z"
        />
        <path
          d="M8624 5661 c-103 -26 -197 -111 -234 -209 -27 -70 -24 -196 4 -252
70 -135 638 -1111 667 -1144 20 -23 61 -55 90 -71 46 -25 68 -30 145 -33 83
-4 97 -2 153 23 76 34 152 113 175 181 25 73 21 188 -8 249 -12 28 -161 291
-331 585 -197 343 -322 550 -349 578 -58 61 -131 94 -214 98 -37 2 -81 0 -98
-5z"
        />
        <path
          d="M9735 3601 c-33 -15 -77 -46 -99 -67 -79 -80 -115 -213 -83 -312 9
-27 161 -300 337 -608 272 -473 329 -567 375 -610 65 -63 121 -84 224 -84 63
0 86 5 136 29 33 15 77 46 99 67 79 80 115 213 83 312 -9 27 -161 300 -337
608 -272 473 -329 567 -375 610 -65 63 -121 84 -224 84 -63 0 -86 -5 -136 -29z"
        />
        <path
          d="M10960 1595 c-118 -33 -205 -123 -236 -245 -24 -96 -10 -166 60 -288
l55 -97 -122 -6 c-139 -6 -202 -28 -275 -94 -92 -82 -127 -238 -82 -357 28
-75 96 -150 168 -185 l57 -28 460 0 c450 0 461 0 507 22 69 31 137 99 169 168
23 48 29 74 29 130 0 39 -5 86 -10 105 -16 58 -414 740 -459 788 -78 82 -209
117 -321 87z"
        />
        <path
          d="M8263 946 c-88 -28 -154 -86 -200 -176 -24 -48 -27 -67 -28 -145 0
-72 4 -100 22 -137 30 -66 96 -133 162 -165 l56 -28 690 0 c686 0 690 0 737
22 66 30 133 96 165 162 24 49 28 67 28 146 -1 79 -4 97 -30 148 -33 67 -97
130 -163 160 -47 22 -53 22 -722 24 -536 2 -684 -1 -717 -11z"
        />
        <path
          d="M5974 951 c-49 -12 -133 -62 -165 -98 -16 -19 -41 -57 -56 -86 -23
-45 -27 -64 -28 -142 0 -72 4 -99 22 -137 30 -66 92 -129 160 -164 l58 -29
690 0 c686 0 690 0 737 22 66 30 133 96 165 162 24 49 28 67 28 146 -1 79 -4
97 -30 148 -33 67 -97 130 -163 160 -47 22 -53 22 -717 24 -368 1 -684 -2
-701 -6z"
        />
        <path
          d="M3743 1595 c-99 -27 -174 -92 -217 -185 -21 -44 -26 -71 -26 -131 0
-42 5 -91 12 -110 18 -54 417 -741 450 -776 17 -17 58 -47 92 -65 l61 -33 438
-3 c368 -2 447 -1 490 12 202 59 299 281 206 472 -25 53 -106 129 -167 157
-45 21 -63 22 -368 25 l-320 3 -145 251 c-79 137 -159 267 -177 289 -70 82
-219 125 -329 94z"
        />
        <path
          d="M2515 3601 c-73 -34 -146 -111 -169 -178 -25 -72 -21 -187 8 -248 12
-27 161 -291 330 -585 259 -451 316 -543 361 -586 65 -63 121 -84 224 -84 63
0 86 5 136 29 71 34 127 87 163 156 24 44 27 62 27 145 0 81 -4 102 -24 139
-13 25 -163 283 -333 575 -257 441 -318 540 -363 582 -65 63 -121 84 -224 84
-63 0 -87 -5 -136 -29z"
        />
        <path
          d="M1391 5654 c-93 -34 -165 -95 -209 -179 -24 -44 -27 -61 -27 -145 0
-81 4 -102 24 -139 13 -25 163 -283 333 -575 257 -441 318 -540 363 -582 65
-63 121 -84 224 -84 99 0 176 34 243 109 57 64 78 122 78 220 0 63 -5 86 -29
136 -66 137 -641 1120 -677 1158 -79 81 -223 118 -323 81z"
        />
        <path
          d="M448 7276 c-149 -54 -232 -168 -231 -316 0 -41 7 -91 14 -111 30 -78
415 -734 456 -775 65 -67 113 -87 220 -92 81 -4 97 -2 146 21 71 32 126 83
162 149 23 43 30 69 33 134 5 97 -4 129 -69 244 l-49 85 118 7 c64 4 136 14
160 22 143 51 233 192 219 343 -12 119 -79 218 -186 270 l-56 28 -450 2 c-353
2 -458 0 -487 -11z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LoadingAnimation',
  props: {
    width: {
      type: Number,
      default: 30
    },
    height: {
      type: Number,
      default: 30
    }
  }
}
</script>

<style scoped>

@keyframes draw {
  0% {
    stroke-dasharray: 0, 100%;
    opacity: 1;
  }
  50% {
    stroke-dasharray: 100%, 0;
    opacity: 1;
  }
  51% {
    stroke-dasharray: 100%, 0;
    opacity: 0;
  }
}

#icon path {
  stroke: black;
  stroke-width: 2;
  fill: black;
  animation: draw 1.68s linear forwards infinite;
  opacity: 0;
}

#icon path:nth-child(1) {
  animation-delay: 0.07s;
}

#icon path:nth-child(2) {
  animation-delay: 0.14s;
}

#icon path:nth-child(3) {
  animation-delay: 0.21s;
}

#icon path:nth-child(4) {
  animation-delay: 0.28s;
}
#icon path:nth-child(5) {
  animation-delay: 0.35s;
}
#icon path:nth-child(6) {
  animation-delay: 0.42s;
}
#icon path:nth-child(7) {
  animation-delay: 0.49s;
}
#icon path:nth-child(8) {
  animation-delay: 0.56s;
}
#icon path:nth-child(9) {
  animation-delay: 0.63s;
}
#icon path:nth-child(10) {
  animation-delay: 0.7s;
}
#icon path:nth-child(11) {
  animation-delay: 0.77s;
}
#icon path:nth-child(12) {
  animation-delay: 0.84s;
}
</style>
