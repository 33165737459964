<template>
  <div class="content-container">
    <Accordion title="Media Format" :badgeCount="activeMediaCount">
      <div
        class="flex-row-start"
        style="margin-top: var(--spacing-xl); cursor: pointer"
        @click="toggleSelectAll"
      >
        <CheckBox
          :model-value="isSelectAll"
          @update:modelValue="toggleSelectAll"
          @click.stop
        />
        <div class="label">Select All</div>
      </div>
      <div
        v-for="(item, i) in media"
        :key="item.id"
        class="flex-row-start"
        style="margin-top: var(--spacing-xl); cursor: pointer"
        @click="toggleMediaSelection(i, item)"
      >
        <CheckBox
          :model-value="item.active"
          @update:modelValue="toggleMediaSelection(i, item)"
          @click.stop
        />
        <div class="label">{{ item.label }}</div>
      </div>
    </Accordion>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Accordion from '../Accordion.vue'
import CheckBox from '../input/CheckBox.vue'

const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    Accordion,
    CheckBox
  },
  computed: {
    ...mapGetters(['media']),
    activeMediaCount () {
      return this.media.filter(type => type.active).length
    },
    isSelectAll () {
      return this.media.every(type => type.active)
    }
  },
  methods: {
    ...mapMutations(['setMediaActive']),
    toggleMediaSelection (i, type) {
      const index = this.media.findIndex(t => t.name === type.name)
      this.setMediaActive({ i: index, val: !type.active })
    },
    toggleSelectAll () {
      const newValue = !this.isSelectAll
      this.media.forEach((type, index) => {
        this.setMediaActive({ i: index, val: newValue })
      })
    }
  },
}
</script>

<style scoped>
.content-container {
  padding: var(--spacing-xxl) 0 var(--spacing-xxl);
}

.label {
  font-size: var(--fs-2);
  line-height: var(--fs-5);
  font-weight: 400;
  margin-left: var(--spacing-md);
}
</style>