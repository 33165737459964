<template>
  <button
    @click="$emit('click')"
    v-ripple
    :disabled="disabled"
    :class="['button', `${size}-size`, `${variant}-${mode}-mode`, { [`disabled-${mode}-mode`]: disabled, 'focused': isActive }]"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'secondary', 'tertiary', 'destructive'].includes(value),
    },
    mode: {
      type: String,
      default: 'light',
      validator: (value) => ['light', 'dark'].includes(value),
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['sm', 'md'].includes(value),
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
}
</script>

<style scoped lang="scss">
.button {
  position: relative;
  text-transform: capitalize;
  cursor: pointer;
  border: 1px solid;
}

.sm-size {
  font-size: var(--fs-2);
  font-weight: 400;
  line-height: var(--fs-5);
  padding: var(--element-padding-xxs);
}

.md-size{
  font-size: var(--fs-3);
  font-weight: 600;
  line-height: var(--fs-6);
  padding: var(--element-padding-md);
}

.primary-light-mode {
  background: var(--color-primary-600);
  color: var(--color-white);
  box-shadow: var(--element-box-shadow) var(--color-primary-100);
  border-color: var(--color-primary-600);
}

.primary-dark-mode {
  background-color: var(--color-primary-300);
  color: var(--color-primary-950);
  box-shadow: var(--element-box-shadow) var(--color-primary-700);
  border-color: var(--color-primary-300);
}

.primary-light-mode:not(.disabled-button):hover {
  background: var(--color-primary-700);
  border-color: var(--color-primary-700);
}

.primary-dark-mode:not(.disabled-button):hover {
  background: var(--color-primary-100);
  border-color: var(--color-primary-100);
}

.secondary-light-mode {
  background: var(--color-white);
  color: var(--color-tan-950);
  box-shadow: var(--element-box-shadow) var(--color-tan-300);
  border-color: var(--color-tan-950);
}

.secondary-dark-mode {
  background: var(--color-primary-950);
  color: var(--color-tan-100);
  box-shadow: var(--element-box-shadow) var(--color-primary-800);
  border-color: var(--color-tan-100);
}

.secondary-light-mode:not(.disabled-button):hover {
  color: var(--color-primary-700);
  border-color: var(--color-primary-700);
}

.secondary-dark-mode:not(.disabled-button):hover {
  color: var(--color-primary-100);
  border-color: var(--color-primary-100);
}

.tertiary-light-mode {
  color: var(--color-tan-950);
  background: var(--color-tan-200);
  box-shadow: var(--element-box-shadow) var(--color-tan-300);
  border-color: var(--color-tan-950);
}

.tertiary-dark-mode {
  background: var(--color-primary-900);
  color: var(--color-tan-100);
  box-shadow: var(--element-box-shadow) var(--color-primary-800);
  border-color: var(--color-tan-100);
}

.tertiary-light-mode:not(.disabled-button):hover {
  background: var(--color-white);
}

.tertiary-light-mode.focused {
  background-color: var(--color-white);
}

.tertiary-dark-mode:not(.disabled-button):hover {
  background: var(--color-primary-950);
}

.destructive-light-mode {
  background: var(--color-white);
  color: var(--color-red-700);
  box-shadow: var(--element-box-shadow) var(--color-red-100);
  border-color: var(--color-red-700);
}

.destructive-dark-mode {
  background: var(--color-primary-950);
  color: var(--color-red-400);
  box-shadow: var(--element-box-shadow) var(--color-red-950);
  border-color: var(--color-red-400);
}

.destructive-light-mode:not(.disabled-button):hover {
  color: var(--color-red-950);
  border-color: var(--color-red-950);
}

.destructive-dark-mode:not(.disabled-button):hover {
  color: var(--color-red-200);
  border-color: var(--color-red-200);
}

.disabled-light-mode {
  cursor: not-allowed;
  background: var(--color-tan-400);
  color: var(--color-white);
  box-shadow: none;
  border: none;
}

.disabled-dark-mode {
  cursor: not-allowed;
  background: var(--color-tan-900);
  color: var(--color-tan-600);
  box-shadow: none;
  border: none;
}
</style>
