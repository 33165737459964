<template>
  <router-link
    class="tool-button"
    @click="$emit('click')"
    :to="href ?? { name: tool }"
    :href="href"
    :target="href ? '_blank' : undefined"
  >
    <Pill
      :title="$t(`nav.${tool}`)"
      :body="$t(`toolDescription.${tool}`)"
      :tool="tool"
    />
  </router-link>
</template>

<script>
import Pill from '../Pill'

export default {
  components: {
    Pill,
  },
  props: {
    tool: String,
    href: String,
  },
}
</script>

<style lang="scss">
.tool-button {
  cursor: pointer;
  text-decoration: none;
  color: var(--color-black);
}
</style>