<template>
  <div
    style="font-weight: 500"
    class="table-text"
  >
    {{ channel }}
  </div>
</template>

<script>
export default {
  props: {
    channel: String,
  },
}
</script>

<style scoped lang="scss">
.author {
  font-size: var(--fs-1);
  font-weight: 300;
  line-height: var(--fs-4);
  color: var(--color-tan-950);
}
</style>