<template>
  <div class="picker-button" @click="modal = true" v-ripple>
    <div class="flex-row-start">
      <IconTool class='tool-icon' :tool='tool' makePurple :size='40' />
      <div style="width: 9.56rem">
        <div class="tool-name">
          {{ $t(`nav.${tool}`) }}
        </div>
      </div>
    </div>
    <div class="right-view">
      <MenuGridIcon class="grid-icon" />
    </div>
  </div>
  <ModalToolPicker v-model:value="modal" />
</template>

<script>
import IconTool from '@/components/input/tool-picker/IconTool'
import ModalToolPicker from '@/components/input/tool-picker/ModalToolPicker'
import MenuGridIcon from '@/assets/svg/color/menuGrid.svg?component'

export default {
  components: {
    IconTool,
    ModalToolPicker,
    MenuGridIcon
  },
  data () {
    return {
      modal: false
    }
  },
  computed: {
    tool () {
      return this.$route.name
    }
  }
}
</script>

<style scoped lang="scss">
.picker-button {
  cursor: pointer;
  border: 1px solid var(--color-tan-100);
  box-shadow: 4px 4px 0px 0px var(--color-primary-700);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tool-icon {
  margin-right: var(--spacing-3);
}

.tool-name {
  font-size: var(--fs-2);
  font-weight: 500;
  color: var(--color-tan-100);
}

.fat-text {
  text-transform: uppercase;
  font-weight: 800;
  font-size: var(--fs-1);
  letter-spacing: 1px;
  color: var(--color-purple);
}


.right-view {
  border-left: 1px solid var(--color-tan-100);
  padding: 0.786rem;
  line-height: 0;
}

.grid-icon {
  width: var(--fs-3);
  height: var(--fs-3);
  color: var(--color-tan-100);
}
</style>
