import { isEmpty } from 'lodash'
import router from '@/router'
const { TWITTER, GAB, FOURCHAN, EIGHTKUN, PARLER, WIN, POAL, TELEGRAM, GETTR, BITCHUTE_VIDEO, BITCHUTE_COMMENT, MEWE, WIMKIN, RUMBLE_VIDEO, RUMBLE_COMMENT, MINDS, LBRY_VIDEO, LBRY_COMMENT, VK, TRUTH_SOCIAL, TIKTOK_VIDEO, TIKTOK_COMMENT, RUTUBE_COMMENT, RUTUBE_VIDEO, OK, FEDIVERSE, BLUESKY } = require('@/constants/sites')
const { LINKS, TIMELINE, ACTIVITY, SEARCH, CRAWLREQUESTS, DASHBOARD, NETWORK } = require('@/constants/tools')

let apiBaseUrl = 'https://staging.api.openmeasures.io/'
if (process.env.VUE_APP_API) { // e.g. http://127.0.0.1:5000
  apiBaseUrl = process.env.VUE_APP_API
  console.info('VUE_APP_API:', apiBaseUrl)
}

function createTerm (settings) {
  const { esquery, searchTerm, types } = settings

  const typeString = types
    .filter(type => type.active)
    .map(type => type.name)
    .map(type => `datatype: ${type}`)
    .join(' OR ')

  let contentString
  if (esquery === 'content') { // Basic
    contentString = `text: "${searchTerm}"`
  } else if (esquery === 'boolean_content') { // Boolean
    contentString = `text: ${searchTerm}`
  } else if (esquery === 'query_string') { // Advanced
    contentString = searchTerm
  } else {
    throw Error('Invalid esquery param')
  }

  return `(${
    [contentString, typeString]
      .filter(Boolean) // only keep filters we've set
      .join(') AND (')
    })`
}

export function queries (settings, page) {
  return settings.websites
    .filter((website) => website.active)
    .map((website) => {
      const { name: site } = website
      const {
        startDate: since,
        endDate: until,
        interval,
      } = settings
      const querytype = 'query_string'
      const url = new URL(apiBaseUrl)

      const term = createTerm(settings)

      switch (page) {
        case LINKS:
          url.pathname = 'content'
          url.search = new URLSearchParams({
            site,
            term,
            since,
            until,
            limit: 10000,
            querytype,
          })
          break

        case TIMELINE:
          url.pathname = 'timeseries'
          url.search = new URLSearchParams({
            site, term, since, until, interval, querytype,
          })

          if (settings.changepoint) url.searchParams.append('changepoint', 'True')

          break

        case ACTIVITY:
          url.pathname = 'activity'
          url.search = new URLSearchParams({
            site,
            term,
            since,
            until,
            agg_by: chooseAggBy(website, settings),
            querytype,
          })
          break

        case SEARCH:
          url.pathname = 'content'
          url.search = new URLSearchParams({
            site,
            term,
            since,
            until,
            querytype,
            // media: true, // uncomment to get only results with media
            returnmedia: true,
            standard_fields: true,
            limit: 1000,
            agg_by: chooseAggBy(website, settings),
          })
          break

        default:
          throw new Error(`Invalid page: ${page}`)
      }

      url.pathname = '/pro' + url.pathname

      return {
        ...website,
        url: url.href
      }
    })
}

function chooseAggBy (website, settings) {
  let aggBy = 'author'
  if (website.name === TWITTER) { aggBy = 'screen_name' }
  if (website.name === GAB) { aggBy = 'account.acct' }
  if (website.name === FOURCHAN) { aggBy = 'name' }
  if (website.name === EIGHTKUN) { aggBy = 'name' }
  if (website.name === PARLER) { aggBy = 'username' }
  if (website.name === WIN) { aggBy = 'author' }
  if (website.name === POAL) { aggBy = 'user' }
  if (website.name === TELEGRAM) { aggBy = 'userinfo.username' }
  if (website.name === GETTR) { aggBy = 'uid' }
  if (website.name === BITCHUTE_VIDEO) { aggBy = 'creator' }
  if (website.name === BITCHUTE_COMMENT) { aggBy = 'fullname' }
  if (website.name === TIKTOK_VIDEO) { aggBy = 'author' }
  if (website.name === TIKTOK_COMMENT) { aggBy = 'author' }
  if (website.name === MEWE) { aggBy = 'userid' }
  if (website.name === WIMKIN) { aggBy = 'author' }
  if (website.name === RUMBLE_VIDEO) { aggBy = 'username' }
  if (website.name === RUMBLE_COMMENT) { aggBy = 'username' }
  if (website.name === MINDS) { aggBy = 'user.username' }
  if (website.name === LBRY_VIDEO) { aggBy = 'signing_channel.name' }
  if (website.name === LBRY_COMMENT) { aggBy = 'channel_name' }
  if (website.name === VK) { aggBy = 'author' }
  if (website.name === TRUTH_SOCIAL) { aggBy = 'account.acct' }
  if (website.name === RUTUBE_VIDEO) { aggBy = 'author.name' }
  if (website.name === RUTUBE_COMMENT) { aggBy = 'user.name' }
  if (website.name === OK) { aggBy = 'author' }
  if (website.name === BLUESKY) { aggBy = 'authorProfile.handle' }
  if (website.name === FEDIVERSE) { aggBy = 'account.acct' }
  return aggBy
}

export async function updateUrl (lastSearchSettings) {
  // haven't made a search yet
  if (isEmpty(lastSearchSettings)) return

  // the default url-ification of the websites object is weird so we'll
  // make it nicer
  const websites = lastSearchSettings.websites
    .filter((website) => website.active)
    .map((website) => website.name)
    .join(',')

  const types = lastSearchSettings.types
    ?.filter((type) => type.active)
    .map((type) => type.name)
    .join(',')

  const media = lastSearchSettings.media
    ?.filter((media) => media.active)
    .map((media) => media.name)
    .join(',')

  const engagements = lastSearchSettings.engagements
    .map((engagement) => {
      const min = engagement.min || '0'
      const max = engagement.max
      if (max) {
        return `${engagement.value}:${min}-${max}`
      }
      return `${engagement.value}:${min}`
    })
    .join(',')

  const cleanQuery = {
    ...lastSearchSettings,
    websites,
    types,
    media,
    ...(engagements && { engagements })
  }
  // TODO make query handling more consistent across app
  if (cleanQuery.changepoint !== undefined) {
    cleanQuery.changepoint = Boolean(cleanQuery.changepoint)
  }

  const currentQuery = new URLSearchParams(router.currentRoute.value.query)
  currentQuery.sort()
  const newQuery = new URLSearchParams(cleanQuery)
  newQuery.sort()

  if (currentQuery.toString() === newQuery.toString()) return

  await router.replace({ query: cleanQuery })
}

export const TOOLS = [TIMELINE, SEARCH, LINKS, ACTIVITY, CRAWLREQUESTS]
export const POPUP_TOOLS = [TIMELINE, SEARCH, LINKS, ACTIVITY, CRAWLREQUESTS, DASHBOARD, NETWORK]

export function isTool (routeName) {
  return TOOLS.includes(routeName)
}
