<template>
  <div class="rules-bar flex-row-center">
    <div style="width: 800px">
      <div style="margin-bottom: var(--spacing-xxl);">
        <div
          v-for="(rule, index) in rulesTypes"
          :key="rule.value"
          class="flex-row-center content-container"
        >
          <Select
            width="15.1428rem"
            size="sm"
            mode="dark"
            :label="rule.type.charAt(0).toUpperCase() + rule.type.slice(1)"
            :options="rulesTypesList"
            @select="handleRulesSelect($event, index)"
          />
          <Select
            width="15.1428rem"
            size="sm"
            mode="dark"
            :label="rule.operator.charAt(0).toUpperCase() + rule.operator.slice(1)"
            :options="operatorList"
            @select="handleOperatorSelect($event, index)"
          />
          <TextInputElement
            width="22.2857rem"
            mode="dark"
            placeholder="Enter keyword(s)"
            @update:modelValue="value => handleKeywordUpdate(value, index)"
          />
          <DeleteIcon class="delete-icon" @click="handleDelete(index)"/>
        </div>
      </div>
      <div class="flex-row-between" style="margin-left: calc(-1 * var(--spacing-xxl))">
        <GhostButton class="flex-row-start" mode="dark" size="sm" @click="handleAddRule">
          <AddIcon class="add-icon" />
          <div>
            Add Rule
          </div>
        </GhostButton>
        <div class="flex-row-start">
          <Button
            variant="secondary"
            mode="dark"
            class="flex-row-center"
            style="margin-right: var(--spacing-xxl)"
            @click="handleClearRules"
          >
            Clear
          </Button>
          <Button class="flex-row-center" mode="dark" @click="handleApply">
            Apply
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Select from './input/Select.vue'
import TextInputElement from './input/TextInputElement.vue'
import GhostButton from './input/GhostButton.vue'
import Button from './input/Button.vue'
import AddIcon from '@/assets/svg/color/add.svg?.component'
import DeleteIcon from '@/assets/svg/color/delete.svg?.component'

const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  data () {
    return {
      rulesTypesList: [
        { label: 'Text', value: 'text' },
        { label: 'Channel', value: 'channel' },
        { label: 'Date', value: 'date' },
        { label: 'Media Type', value: 'mediaType' },
      ],
      operatorList: [
        { label: '= Equals', value: 'equals' },
        { label: '!= Not Equals', value: 'notEquals' },
        { label: 'Contains', value: 'contains' },
        { label: 'Not Contains', value: 'notContains' },
      ],
      rulesTypes: [],
    }
  },
  components: {
    Select,
    TextInputElement,
    GhostButton,
    Button,
    DeleteIcon,
    AddIcon
  },
  computed: {
    ...mapGetters(['rules']),
  },
  methods: {
    ...mapMutations(['addRules', 'removeRules']),
    handleRulesSelect (newValue, index) {
      this.rulesTypes[index] = {
        ...this.rulesTypes[index],
        type: newValue.value
      }
    },
    handleOperatorSelect (newValue, index) {
      this.rulesTypes[index] = {
        ...this.rulesTypes[index],
        operator: newValue.value
      }
    },
    handleKeywordUpdate (value, index) {
      this.rulesTypes[index] = {
        ...this.rulesTypes[index],
        inputTerm: value
      }
    },
    handleAddRule () {
      this.rulesTypes.push({
        type: 'text',
        operator: 'equals',
        inputTerm: ''
      })
    },
    handleDelete (index) {
      this.rulesTypes.splice(index, 1)
    },
    handleApplyRules () {
      this.addRules(this.rulesTypes)
    },
    handleClearRules () {
      this.rulesTypes = []
    }
  }
}
</script>

<style scoped>
.rules-bar {
  width: 100%;
  background: var(--color-primary-950);
  padding: var(--spacing-huge);
}

.content-container {
  gap: var(--spacing-xxl);
  margin-bottom: var(--spacing-xxl);
}

.delete-icon {
  width: var(--fs-3);
  height: var(--fs-3);
  color: var(--color-tan-400);
  cursor: pointer;
}

.add-icon {
  width: var(--fs-3);
  height: var(--fs-3);
  margin-right: var(--spacing-md);
  cursor: pointer;
}
</style>