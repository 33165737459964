<template>
  <div class="table-header">
    <div
      v-for="cell in columnNames"
      :key="cell.name"
      class="header-text"
      :style="cell.style"
    >
      {{ cell.name }}
    </div>
  </div>
  <LoadingAnimation v-if="loading" class="loading" />
  <div v-else>
    <div v-if="resultsData.length">
      <div class="table-row" v-for="(row, index) in paginatedData" :key="index">
        <SearchRow
          :row="row"
          :blurThumbnails="blurThumbnails"
        />
      </div>
    </div>
    <PaginationBar
      :totalResults="resultsData.length"
      :totalPages="totalPages"
      :currentPage="currentPage"
      @page-change="handlePageChange"
    />
  </div>
</template>

<script>
import SearchRow from './SearchRow.vue'
import LoadingAnimation from '@/animations/LoadingAnimation.vue'
import PaginationBar from '../PaginationBar.vue'

export default {
  components: {
    SearchRow,
    PaginationBar,
    LoadingAnimation
  },
  props: {
    resultsData: Array,
    loading: Boolean,
    displayMedia: Boolean,
    blurThumbnails: Boolean,
  },
  data () {
    return {
      currentPage: 1,
      itemsPerPage: 50
    }
  },
  computed: {
    columnNames () {
      return [
        { name: 'date & time', style: 'flex: 3' },
        { name: 'type', style: 'flex: 2' },
        { name: 'text', style: 'flex: 8' },
        { name: 'site', style: 'flex: 3' },
        { name: 'channel', style: 'flex: 3' },
        { name: 'author', style: 'flex: 3' },
        { name: 'engagement', style: 'flex: 2' },
        { name: 'mediatype', style: 'flex: 3' },
        { name: 'thumbnail', style: 'flex: 3' },
        { name: '', style: 'flex: 1' }
      ]
    },
    totalPages () {
      return Math.ceil(this.resultsData.length / this.itemsPerPage)
    },
    paginatedData () {
      const start = (this.currentPage - 1) * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.resultsData.slice(start, end)
    },
  },
  methods: {
    handlePageChange (page) {
      this.currentPage = page
    }
  }
}
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--color-tan-300);
  position: sticky;
  top: 5rem;
  z-index: 2;
  background-color: var(--color-white);
}

.header-text {
  font-size: var(--fs-1);
  font-weight: 500;
  line-height: var(--fs-4);
  color: var(--color-tan-600);
  text-transform: capitalize;
  padding: var(--spacing-md);
}

.table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  border-bottom: 1px solid var(--color-tan-300);
  padding: var(--spacing-md) 0;
}

.loading {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>