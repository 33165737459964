<template>
  <div class="content-container">
    <Accordion title="Engagement" :badgeCount="activeEngagementCount">
      <div style="margin: var(--spacing-xl) 0px">
        <div
          v-for="(engagement, index) in engagements"
          :key="engagement.value"
          style="padding-bottom: var(--spacing-xl)"
          class="flex-row-center"
        >
          <div style="flex: 1" class="flex-row-center">
            <IconButton
              variant="secondary"
              mode="light"
              @click="handleRemoveEngagements(index)"
              style="margin-right: var(--spacing-xs); box-shadow: none"
            >
              <BaseIcon style="color: var(--color-red-700)" name="close" :size="'var(--fs-3)'" />
            </IconButton>
            <Select
              width="9rem"
              size="sm"
              :label="engagement.value.charAt(0).toUpperCase() + engagement.value.slice(1)"
              :options="availableEngagements"
              @select="handleEngagementSelect($event, engagement.value)"
            />
          </div>
          <div
            style="gap: var(--spacing-md); margin-left: var(--spacing-md); flex: 1"
            class="flex-row-center"
          >
            <TextInputElement
              :modelValue="engagement.min"
              @update:modelValue="value => handleMinMaxUpdate(index, 'min', value)"
              inputStyle="text-align: center"
              disableCornerStyle
            />
            <div>to</div>
            <TextInputElement
              :modelValue="engagement.max"
              @update:modelValue="value => handleMinMaxUpdate(index, 'max', value)"
              inputStyle="text-align: center"
              disableCornerStyle
            />
          </div>
        </div>
        <IconButton
          variant="secondary"
          mode="light"
          v-if="hasAvailableEngagements"
          @click="handleAddNewEngagement"
          style="margin-bottom: var(--spacing-xl)"
        >
          <BaseIcon name="add" :size="'var(--fs-3)'" />
        </IconButton>
      </div>
    </Accordion>
  </div>
  <Border />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Accordion from '../Accordion.vue'
import Select from '../input/Select.vue'
import TextInputElement from '../input/TextInputElement.vue'
import IconButton from '../input/IconButton.vue'
import BaseIcon from '../BaseIcon.vue'
import Border from '../Border.vue'

const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  data () {
    return {
      engagementsTypes: [
        { value: 'likes' },
        { value: 'dislikes' },
        { value: 'reshares' },
        { value: 'replies' },
        { value: 'views' }
      ]
    }
  },
  components: {
    Accordion,
    Select,
    TextInputElement,
    IconButton,
    BaseIcon,
    Border
  },
  computed: {
    ...mapGetters(['engagements']),
    hasAvailableEngagements () {
      return this.engagementsTypes.length !== this.engagements.length
    },
    activeEngagementCount () {
      return this.engagements.length
    },
    availableEngagements () {
      return this.engagementsTypes
        .filter(eng => !this.engagements.map(e => e.value).includes(eng.value))
        .map(eng => ({
          ...eng,
          label: eng.value.charAt(0).toUpperCase() + eng.value.slice(1)
        }))
    }
  },
  methods: {
    ...mapMutations(['addEngagements', 'removeEngagements', 'updateEngagement', 'updateEngagementMinMax']),
    handleEngagementSelect (newValue, stateValue) {
      if (!newValue) return

      // check if change is being made to an existing engagement
      const isExistingEngagement = this.engagements.find(eng => eng.value === stateValue)
      if (isExistingEngagement) {
        this.updateEngagement({
          previousValue: stateValue,
          value: newValue.value,
          min: newValue.min,
          max: newValue.max
        })
      } else {
        this.addEngagements({
          value: newValue
        })
      }
    },

    handleMinMaxUpdate (index, field, value) {
      this.updateEngagementMinMax({
        i: index,
        field,
        value
      })
    },

    handleRemoveEngagements (index) {
      this.removeEngagements(index)
    },

    handleAddNewEngagement () {
      if (this.availableEngagements.length) {
        this.addEngagements({
          value: this.availableEngagements[0].value,
        })
      }
    }
  }
}
</script>
<style scoped>
.content-container {
  padding: var(--spacing-xxl) 0 var(--spacing-xxl);
}

.flex-row-center {
  display: flex;
  align-items: center;
}
</style>