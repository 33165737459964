<template>
  <q-inner-loading :showing="loading">
    <div class="spinner-container">
      <LoadingAnimation width="50" height="50" />
    </div>
  </q-inner-loading>
</template>

<script>
import LoadingAnimation from '@/animations/LoadingAnimation.vue'

export default {
  components: {
    LoadingAnimation,
  },
  props: {
    loading: Boolean
  },
}
</script>

<style scoped lang="scss">
.spinner-container {
  height: 100%;
  margin-top: 30vh;
}
</style>
