<template>
  <div class="toggle-button">
    <div
      v-for="option in options"
      :key="option.value"
      @click="$emit('click', option.value)"
      :class="['button', { 'button-active': option.value === value }]"
    >
      <component
        :is="option.component"
        style="width: 16px; height: 16px"
        :class="['toggle-icon', { 'toggle-icon-active': option.value === value }]"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
}
</script>

<style scoped lang="scss">
.toggle-button {
  position: relative;
  cursor: pointer;
  background: transparent;
  box-shadow: var(--element-box-shadow) var(--color-tan-300);
  border: 1px solid var(--color-tan-950);
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 0;
  align-self: flex-start;
}

.button {
  padding: var(--spacing-xl);
}

.button:hover {
  background: var(--color-primary-100);
}

.button-active {
  background: var(--color-primary-600);
}

.button-active:hover {
  background: var(--color-primary-700);
}

.toggle-icon {
  color: var(--color-tan-950);
  width: 16px;
  height: 16px;
  overflow: visible;
}

.toggle-icon-active {
  color: var(--color-white);
}

</style>