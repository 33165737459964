<template>
  <div class="cell-wrapper" style="flex: 3">
    <DateCell :date="row?.date"/>
  </div>
  <div class="cell-wrapper" style="flex: 2">
    <TypeCell :type="row?.type"/>
  </div>
  <div class="cell-wrapper" style="flex: 8">
    <TextCell :text="row?.text"/>
  </div>
  <div class="cell-wrapper" style="flex: 3">
    <SourceCell
      :value="row.site.name"
      :label="row.site.label"
    />
  </div>
  <div class="cell-wrapper" style="flex: 3">
    <ChannelCell :channel="row.channel" />
  </div>
  <div class="cell-wrapper" style="flex: 3">
    <AuthorCell :author="row.author" />
  </div>
  <div class="cell-wrapper" style="flex: 2">
    <EngagementCell :cell="row.engagement"/>
  </div>
  <div class="cell-wrapper" style="flex: 3">
    <div>{{ row.mediatype }}</div>
  </div>
  <div class="cell-wrapper" style="flex: 3">
    <MediaCell
      :cell="row.media"
      :row="row"
      :blurThumbnails="blurThumbnails"
    />
  </div>
  <div class="cell-wrapper" style="flex: 1">
    <MenuCell />
  </div>
</template>

<script>
import DateCell from '../DateCell.vue'
import TypeCell from './TypeCell.vue'
import TextCell from './TextCell.vue'
import SourceCell from '../SourceCell.vue'
import ChannelCell from '../ChannelCell.vue'
import AuthorCell from '../AuthorCell.vue'
import EngagementCell from './EngagementCell.vue'
import MediaCell from './MediaCell.vue'
import MenuCell from './MenuCell.vue'


export default {
  components: {
    DateCell,
    TypeCell,
    TextCell,
    SourceCell,
    ChannelCell,
    AuthorCell,
    EngagementCell,
    MediaCell,
    MenuCell,
  },
  props: {
    row: Object,
    blurThumbnails: Boolean,
  },
}
</script>

<style scoped>
.search-cell {
  font-size: var(--fs-1);
  color: var(--color-tan-950);
}

.cell-wrapper {
  display: flex;
  align-items: center;
  min-width: 0;
  padding: var(--spacing-md);
  overflow: hidden;
}
</style>