<template>
  <DropdownWrapper
    :isOpen="isOpen"
    :label="label"
    :width="width"
    @toggle="toggleDropdown"
    :size="size"
    :mode="mode"
  >
    <DropDown :mode="mode">
      <div
        v-for="(option, index) in options"
        :key="index"
        :class="['dropdown-item', `dropdown-item-${mode}-mode`]"
        @click="selectOption(option)"
      >
        {{ option.label }}
      </div>
    </DropDown>
  </DropdownWrapper>
</template>

<script>
import DropDown from './DropDown.vue'
import DropdownWrapper from './DropdownWrapper.vue'

export default {
  name: 'SingleSelect',
  props: {
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: 'light',
      validator: (value) => ['light', 'dark'].includes(value),
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['sm', 'md'].includes(value),
    },
    width: String
  },
  data () {
    return {
      isOpen: false,
    }
  },
  components: {
    DropdownWrapper,
    DropDown
  },
  methods: {
    toggleDropdown () {
      this.isOpen = !this.isOpen
    },
    selectOption (option) {
      this.$emit('select', option)
      this.isOpen = false
    },
  },

}
</script>

<style scoped>
.dropdown-item {
  padding: var(--spacing-xxl);
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-item-light-mode {
  color: var(--color-tan-600);
}

.dropdown-item-dark-mode {
  color: var(--color-tan-400);
}

.dropdown-item:hover {
  color: var(--color-primary-950);
  background-color: var(--color-primary-100);
}
</style>