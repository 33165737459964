<template>
  <div :style="{ height: maxHeight }" :class="['dropdown-list', `dropdown-list-${mode}-mode`]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: 'light',
      validator: (value) => ['light', 'dark'].includes(value),
    },
    maxHeight: {
      type: String,
      default: 'fit-content',
    },
  },
}
</script>

<style scoped>
.dropdown-list {
  position: absolute;
  width: 100%;
  border: var(--border-width-1) solid;
  margin-top: 4px;
  z-index: 1;
  overflow-y: auto;
}

.dropdown-list-light-mode {
  border-color: var(--color-tan-950);
  background-color: var(--color-white);
  box-shadow: var(--element-box-shadow) var(--color-tan-300);
}

.dropdown-list-dark-mode {
  border-color: var(--color-tan-100);
  background-color: var(--color-primary-950);
  box-shadow: var(--element-box-shadow) var(--color-primary-800);
}
</style>