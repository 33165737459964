<template>
  <InputContainerLayout
    :width="width"
    :label="label"
    :hasValue="hasValue"
    :hasError="hasError"
    :disabled="disabled"
    :mode="mode"
    :disableCornerStyle="disableCornerStyle"
  >
    <input
      type="text"
      :disabled="disabled"
      :placeholder="placeholder"
      :value="modelValue"
      @input="updateValue($event.target.value)"
      @blur="validateInput"
      :class="['input', `input-${mode}-mode`]"
      :style="inputStyle"
    />
    <BaseIcon
      v-if="hasError"
      name="close"
      size="16px"
      @icon-clicked="clearInput"
      :style="{ cursor: 'pointer', color: mode === 'dark' ? 'var(--color-tan-300)' : 'var(--color-tan-800))' }"
    />
    <div class="slot">
      <slot name="append"></slot>
    </div>
  </InputContainerLayout>
  <div v-if="hasError" :class="['error-text', `error-${mode}-mode`]">
    {{ errorMessage }}
  </div>
</template>

<script>
import closeIcon from '../../assets/close.svg'
import BaseIcon from '@/components/BaseIcon'
import InputContainerLayout from './InputContainerLayout.vue'

export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorRegex: {
      type: RegExp,
      default: false
    },
    errorMessage: {
      type: String,
      default: 'Invalid input'
    },
    mode: {
      type: String,
      default: 'light'
    },
    disableCornerStyle: {
      type: Boolean,
      default: false
    },
    width: String,
    inputStyle: Object
  },
  emits: ['update:modelValue'],
  components: {
    InputContainerLayout,
    BaseIcon
  },
  data () {
    return {
      touched: false,
      closeIcon,
    }
  },
  computed: {
    hasValue () {
      return this.modelValue.length > 0
    },
    hasError () {
      if (this.touched && this.errorRegex) {
        const regex = new RegExp(this.errorRegex)
        return !regex.test(this.modelValue)
      }
      return false
    }
  },
  methods: {
    validateInput () {
      this.touched = true
    },
    clearInput () {
      this.$emit('update:modelValue', '')
      this.touched = false
    },
    updateValue (value) {
      this.$emit('update:modelValue', value) // Emit to update the parent's value
    }
  }
}
</script>

<style scoped>
.input {
  border: none;
  font-size: var(--fs-1);
  font-family: var(--primary-font);
  outline: none;
  width: 100%;
}

.input-light-mode {
  background-color: var(--color-white);
  color: var(--color-tan-950);
}

.input-dark-mode {
  background-color: var(--color-primary-950);
  color: var(--color-tan-100);
}

.input-light-mode::placeholder {
  color: var(--color-tan-600);
}

.input-dark-mode::placeholder {
  color: var(--color-tan-400);
}

.input-light-mode:disabled::placeholder {
  color: var(--color-tan-400) !important;
}

.input-dark-mode:disabled::placeholder {
  color: var(--color-tan-700) !important;
}

.close-icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.error-text {
  font-size: var(--fs-1);
  font-family: var(--primary-font);
  margin-top: var(--spacing-md);
}
.error-light-mode {
  color: var(--color-red-700);
}

.error-dark-mode {
  color: var(--color-red-400);
}

.slot {
  color: initial;
}

.slot {
  color: initial;
}

.slot {
  color: initial;
}
</style>