<template>
  <div class="banner">
    {{ $t("home.banner") }}
  </div>
  <div>
    <div class="home-header text-title-1">
      {{ $t("home.header") }}
    </div>
    <div class="home-body">
      <div class="home-column">
        <ToolPill
          v-if="scopes.includes('access:kibana')"
          :tool="DASHBOARD"
          href="https://smat-cloud.kb.us-east1.gcp.elastic-cloud.com:9243"
        />
        <ToolPill
          v-if="scopes.includes('query:graphql')"
          :tool="NETWORK"
          href="https://graph.openmeasures.io"
        />
        <ToolPill
          v-if="scopes.includes('access:media')"
          :tool="MEDIA"
          href="https://media.openmeasures.io"
        />
        <Pill
          title="Pro API Endpoint"
          body="https://api.openmeasures.io"
          v-if="scopes.includes('query:pro_api')"
          tool="api"
          doCopy
        />
      </div>
      <div class="home-column" v-if="scopes.includes('query:pro_api')">
        <ToolPill v-for="tool in TOOLS" :tool="tool" :key="tool"/>
      </div>
    </div>
  </div>
</template>

<script>
import Pill from '@/components/Pill'
import ToolPill from '@/components/home/ToolPill'
import { CRAWLREQUESTS, MEDIA, NETWORK, DASHBOARD } from '../constants/tools'
import { TOOLS } from '@/helpers/searchQuery'

export default {
  components: {
    Pill,
    ToolPill,
  },
  data: function () {
    return {
      modal: true,
      scopes: JSON.parse(
        window.localStorage.getItem(
          Object.keys(window.localStorage).filter(key => key.startsWith('@@'))[0]
        )
      ).body.oauthTokenScope,
      CRAWLREQUESTS,
      MEDIA,
      NETWORK,
      DASHBOARD,
      TOOLS,
    }
  }
}
</script>

<style scoped lang="scss">
$page-padding: 40px;
$spacing-side-padding: 122px;
$inner-padding: 20px;

.banner {
  width: 100%;
  background-color: var(--color-blue);  /* Change this to your preferred color */
  color: #212529;  /* Change this to your preferred color */
  text-align: center;
  padding: 10px 0;
  font-family: var(--primary-font);
  font-size: 15px;
}

body {
  background: var(--color-light-bg);
}

.home-body {
  padding: $page-padding $spacing-side-padding;
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: var(--color-black);
}

.home-column {
  margin-right: $inner-padding;
}

.home-header {
  padding: $page-padding $spacing-side-padding;
  width: calc(100vw - 2 * $spacing-side-padding);
  border-bottom: 2px solid var(--color-off-white);
  position: relative;
  color: var(--color-black);
}

.icon-case {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-black);
}

@media (max-width: 1000px) {
  .home-body {
    padding: calc($page-padding / 2) 10px;
    grid-template-columns: 1fr;
  }

  .home-header {
    padding: calc($page-padding / 2) 10px;
  }

  .home-column {
    margin-right: 0;
  }

  /* analysis tools should show up above the rest on mobile */
  .home-column:last-child {
    grid-row: -1;
  }
}
</style>
