<template>
  <q-tooltip
    anchor="bottom middle"
    self="top middle"
    :class="{ 'error-tooltip': isError }"
    :model-value="alwaysShow ? true : null"
    :no-parent-event="alwaysShow"
  >
    <div class="tooltip-content">
      <slot/>
    </div>
  </q-tooltip>
</template>

<script>
export default {
  props: {
    alwaysShow: Boolean,
    isError: Boolean,
  }
}
</script>

<style scoped lang="scss">
.tooltip-content {
  font-family: var(--primary-font);
  font-size: var(--fs-1);
  color: var(--color-tan-950);
}
</style>

<style lang="scss">
.error-tooltip {
  background: var(--color-error);
}

.q-tooltip--style {
  background: var(--color-white) !important;
  border-radius: 2px !important;
  box-shadow: 2px 2px 0 var(--color-tan-300); /* Hard shadow */
  padding: 4px 8px !important;
}
</style>