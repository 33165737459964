<template>
  <div>
    <div class="table-text">
      {{ moment(date).format('YYYY-MM-DD') }}
    </div>
    <div class="table-text">
      {{ moment(date).format('HH:mm A') }}
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    date: String,
  },
  data () {
    return {
      moment,
    }
  },
}
</script>