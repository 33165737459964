<template>
  <div class="engagement-row">
    <div class="flex-row-start">
      <div class="text-reaction">
        {{ engagement.reshares }}
      </div>
      <CommentIcon color="var(--color-primary-600)" style="margin-left: var(--spacing-xs)" />
    </div >
    <div class="flex-row-start">
      <div class="text-reaction">
        {{ engagement.likes }}
      </div>
      <LikeIcon color="var(--color-primary-600)" style="margin-left: var(--spacing-xs)" />
    </div>
    <div class="flex-row-start">
      <div class="text-reaction">
        {{ engagement.replies }}
      </div>
      <ReplyIcon color="var(--color-primary-600)" style="margin-left: var(--spacing-xs)" />
    </div>
  </div>
</template>

<script>
import LikeIcon from '../../assets/svg/color/like.svg?component'
import CommentIcon from '../../assets/svg/color/comment.svg?component'
import ReplyIcon from '../../assets/svg/color/reply.svg?component'

export default {
  components: {
    LikeIcon,
    CommentIcon,
    ReplyIcon
  },
  props: {
    engagement: Object,
  },
}
</script>

<style scoped>
.engagement-row {
  display: flex;
  gap: var(--spacing-md);
}

.text-reaction {
  font-size: var(--fs-2);
  line-height: var(--fs-5);
  font-weight: 400;
  color: var(--color-tan-950);
}
</style>