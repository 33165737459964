<template>
  <div class="dropdown">
    <button
      :style="{
        width: width,
      }"
      class="dropdown-button"
      :class="[
        `dropdown-button-${mode}-mode`,
        {
          'open': isOpen,
          'filled': value,
          'is-disabled': disabled,
          'has-error': error
        }
      ]"
      @click="toggleDropdown"
    >
      {{ value || label }}
      <RotateArrow
        :size="size === 'md' ? '24px' : '20px'"
        :disabled="disabled"
        :rotate="isOpen"
        :color="disabled
          ? mode === 'light' ? 'var(--color-tan-400)' : 'var(--color-tan-700)'
          : mode === 'light' ? 'var(--color-tan-800)' : 'var(--color-tan-300)'"
      />
    </button>

    <div v-if="!disabled && isOpen">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import RotateArrow from '@/components/RotateArrow'

export default {
  name: 'DropdownElement',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'light'
    },
    width: {
      type: String,
    },
    error: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['sm', 'md'].includes(value),
    },
    value: String,
  },
  components: {
    RotateArrow
  },
  methods: {
    toggleDropdown () {
      this.$emit('toggle')
    },
  },
}
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  position: relative;
  padding: var(--element-padding-md);
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
  font-size: var(--fs-2);
  line-height: var(--fs-5);
}

.dropdown-button::after {
  content: "";
  position: absolute;
  bottom: -3.5px;
  right: -3.5px;
  width: 7px;
  height: 7px;
  border: 1px solid;
  box-sizing: border-box;
}

.dropdown-button-light-mode {
  border-color: var(--color-tan-600);
  background-color: var(--color-white);
  color: var(--color-tan-600);
}

.dropdown-button-dark-mode {
  border-color: var(--color-tan-400);
  background-color: var(--color-primary-950);
  color: var(--color-tan-100);
}

.dropdown-button-light-mode::after {
  border-color: var(--color-tan-600);
  background-color: var(--color-white);
}

.dropdown-button-dark-mode::after {
  border-color: var(--color-tan-400);
  background-color: var(--color-primary-950);
}

/* when has value */
.dropdown-button-light-mode.filled {
  border-color: var(--color-tan-950);
  color: var(--color-tan-950);
}

.dropdown-button-light-mode.filled::after {
  border-color: var(--color-tan-950);
  background-color: var(--color-tan-950);
}

.dropdown-button-dark-mode.filled {
  border-color: var(--color-tan-100);
  color: var(--color-tan-100)
}

.dropdown-button-dark-mode.filled::after {
  border-color: var(--color-tan-100);
}

.dropdown-button-light-mode:hover {
  border-color: var(--color-primary-600);
}

.dropdown-button-dark-mode:hover {
  border-color: var(--color-primary-300);
}

.dropdown-button-light-mode:hover::after {
  border-color: var(--color-primary-600);
  background-color: var(--color-primary-600);
}

.dropdown-button-dark-mode:hover::after {
  border-color: var(--color-primary-300);
  background-color: var(--color-primary-300);
}

.dropdown-button-light-mode.open {
  border-color: var(--color-primary-600);
}

.dropdown-button-light-mode.open::after {
  border-color: var(--color-primary-600);
  background-color: var(--color-primary-600);
}

.dropdown-button-dark-mode.open {
  border-color: var(--color-primary-300);
}

.dropdown-button-dark-mode.open::after {
  border-color: var(--color-primary-300);
  background-color: var(--color-primary-300);
}

/* when there is an error */
.dropdown-button-light-mode.has-error {
  border-color: var(--color-red-700) !important;
}

.dropdown-button-light-mode.has-error::after {
  border-color: var(--color-red-700) !important;
  background-color: var(--color-red-700) !important;
}

.dropdown-button-dark-mode.has-error {
  border-color: var(--color-red-400) !important;
}

.dropdown-button-dark-mode.has-error::after {
  border-color: var(--color-red-400) !important;
  background-color: var(--color-red-400) !important;
}

/* when disabled */
.dropdown-button-light-mode.is-disabled {
  color: var(--color-tan-400) !important;
  border-color: var(--color-tan-400) !important;
  cursor: not-allowed;
}

.dropdown-button-dark-mode.is-disabled {
  color: var(--color-tan-700) !important;
  border-color: var(--color-tan-700) !important;
  cursor: not-allowed;
}

.dropdown-button.is-disabled::after  {
  display: none;
}

</style>