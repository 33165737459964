<template>
  <div class="search-term-wrapper">
    <div
      class="search-term-bar"
    >
      <div class="search-term-content">
        <InputSearchTerm/>
        <GhostButton
          style="margin-left: calc(-1 * var(--spacing-xxl))"
          mode="dark"
          @click="showRules = !showRules"
        >
          {{ showRules ? 'Hide Rules' : 'Add Rule' }}
        </GhostButton>
      </div>
    </div>
    <AnimatedAccordion :isExpanded="showRules">
      <RulesBar />
    </AnimatedAccordion>
  </div>
</template>

<script>
import AnimatedAccordion from '@/animations/AnimatedAccordion.vue'
import InputSearchTerm from './input/InputSearchTerm.vue'
import GhostButton from './input/GhostButton.vue'
import RulesBar from './RulesBar.vue'

export default {
  components: {
    AnimatedAccordion,
    InputSearchTerm,
    GhostButton,
    RulesBar,
  },
  data () {
    return {
      showRules: false
    }
  },
  methods: {
    handleScroll () {
      this.showRules = false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
}
</script>

<style scoped lang="scss">
.search-term-wrapper {
  position: relative;
  overflow: hidden;
}

.search-term-bar {
  background: url(~@/assets/pixely-bg.png) var(--color-primary-900);
  border-bottom: var(--border-width-4) solid var(--color-primary-800);
}

.search-term-content {
  margin: auto;
  max-width: 800px;
  padding: var(--spacing-huge) 0;

}
</style>