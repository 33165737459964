import moment from 'moment'
import { isEmpty, cloneDeep, isEqual } from 'lodash'
import router from '@/router'
import auth0 from '@/plugins/auth0'
import { validInput } from '@/helpers/validate'
import { queries as buildQueries, updateUrl, isTool } from '@/helpers/searchQuery'
const { FOURCHAN, EIGHTKUN, PARLER, GAB, WIN, POAL, TELEGRAM, GETTR, BITCHUTE_VIDEO, BITCHUTE_COMMENT, MEWE, WIMKIN, RUMBLE_COMMENT, RUMBLE_VIDEO, MINDS, LBRY_VIDEO, LBRY_COMMENT, VK, TRUTH_SOCIAL, TIKTOK_VIDEO, TIKTOK_COMMENT, RUTUBE_COMMENT, RUTUBE_VIDEO, OK, BLUESKY, FEDIVERSE } = require('@/constants/sites')
const { TIMELINE, SEARCH, LINKS, ACTIVITY } = require('@/constants/tools')

export default {
  namespaced: true,
  state: {
    settings: {
      searchTerm: 'qanon',
      startDate: moment().subtract(0.5, 'year').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      types: [
        { label: 'Message', name: 'message', active: false },
        { label: 'Post', name: 'post', active: false },
        { label: 'Comment', name: 'comment', active: false },
      ],
      media: [
        { label: 'Image', name: 'image', active: false },
        { label: 'Video', name: 'video', active: false },
        { label: 'PDF', name: 'pdf', active: false },
        { label: 'MP3', name: 'mp3', active: false },
      ],
      websites: [
        {
          name: EIGHTKUN,
          label: '8kun',
          active: false
        },
        {
          name: FOURCHAN,
          label: '4chan',
          active: false
        },
        {
          name: BLUESKY,
          label: 'Bluesky',
          active: false
        },
        {
          name: BITCHUTE_VIDEO,
          label: 'Bitchute Video',
          active: false
        },
        {
          name: BITCHUTE_COMMENT,
          label: 'Bitchute Comment',
          active: false
        },
        {
          name: FEDIVERSE,
          label: 'Fediverse',
          active: false
        },
        {
          name: GAB,
          label: 'Gab',
          active: true
        },
        {
          name: GETTR,
          label: 'Gettr',
          active: false
        },
        {
          name: LBRY_COMMENT,
          label: 'LBRY Comment',
          active: false
        },
        {
          name: LBRY_VIDEO,
          label: 'LBRY Video',
          active: false
        },
        {
          name: MEWE,
          label: 'MeWe',
          active: false
        },
        {
          name: MINDS,
          label: 'Minds',
          active: false
        },
        {
          name: OK,
          label: 'OK',
          active: false
        },
        {
          name: PARLER,
          label: 'Parler',
          active: false
        },
        {
          name: POAL,
          label: 'Poal',
          active: false
        },
        {
          name: RUMBLE_VIDEO,
          label: 'Rumble Video',
          active: false
        },
        {
          name: RUMBLE_COMMENT,
          label: 'Rumble Comment',
          active: false
        },
        {
          name: RUTUBE_VIDEO,
          label: 'RUTUBE Video',
          active: false
        },
        {
          name: RUTUBE_COMMENT,
          label: 'RUTUBE Comment',
          active: false
        },
        {
          name: TELEGRAM,
          label: 'Telegram',
          active: false
        },
        {
          name: TIKTOK_VIDEO,
          label: 'TikTok Video',
          active: false
        },
        {
          name: TIKTOK_COMMENT,
          label: 'TikTok Comment',
          active: false
        },
        {
          name: TRUTH_SOCIAL,
          label: 'Truth Social',
          active: false
        },
        {
          name: VK,
          label: 'VK',
          active: false
        },
        {
          name: WIMKIN,
          label: 'WiMKiN',
          active: false
        },
        {
          name: WIN,
          label: 'Win Communities',
          active: false
        }
      ],
      engagements: [],
      rules: [],
      numberOf: 10,
      interval: 'day',
      changepoint: false,
      esquery: 'content',
      hostRegex: true,
    },
    toolResults: {
      [TIMELINE]: [],
      [SEARCH]: [],
      [LINKS]: [],
      [ACTIVITY]: [],
    },
    sidebarMiniState: false,
    scrollPosition: 196, // 196px is the height of the SearchTermBar
    error: null,
    lastSearchSettings: {},
    searchHistory: null,
  },
  getters: {
    page () {
      return router.currentRoute.value.name
    },
    scrollPosition (state) {
      return state.scrollPosition
    },
    sidebarMiniState (state) {
      return state.sidebarMiniState
    },
    results (state, getters) {
      return state.toolResults[getters.page]
    },
    resultsAvailable (state, getters) {
      return getters.results.length > 0
    },
    numberOfResults (state, getters) {
      let num = 0

      switch (getters.page) {
        case SEARCH:
        case TIMELINE:
        case LINKS:
        case ACTIVITY:
        // TODO: links and activity probably need more filtering, since they only show some of these results (i.e. the number returned here is too high)
          num = getters.results
            .map(siteResults => siteResults.data.total_hits)
            .reduce((sum, val) => sum + val, 0)
          break
        default:
          break // shouldn't end up here but no biggie if we do
      }

      return num
    },
    loading (state, getters) {
      return !getters.resultsAvailable && !state.error
    },
    isSidebarFiltersModified (state) {
      return !isEqual(state.lastSearchSettings, state.settings)
    },
    searchTerm (state) {
      return state.settings.searchTerm
    },
    startDate (state) {
      return state.settings.startDate
    },
    endDate (state) {
      return state.settings.endDate
    },
    types (state) {
      return state.settings.types
    },
    media (state) {
      return state.settings.media
    },
    engagements (state) {
      return state.settings.engagements
    },
    rules (state) {
      return state.settings.rules
    },
    websites (state) {
      return state.settings.websites
    },
    numberOf (state) {
      return state.settings.numberOf
    },
    interval (state) {
      return state.settings.interval
    },
    changepoint (state) {
      return state.settings.changepoint
    },
    esquery (state) {
      return state.settings.esquery
    },
    hostRegex (state) {
      return state.settings.hostRegex
    },
    searchHistory (state) {
      return state.searchHistory
    },
    selectedSidebarFilterCategoryCount (state) {
      return [
        state.settings.types.some(type => type.active),
        state.settings.media.some(media => media.active),
        state.settings.websites.some(website => website.active),
        state.settings.engagements.length > 0,
        state.settings.startDate || state.settings.endDate
      ].reduce((count, isActive) => count + (isActive ? 1 : 0), 0)
    }
  },
  mutations: {
    setScrollPosition (state, val) {
      state.scrollPosition = val
    },
    setSidebarMiniState (state, val) {
      state.sidebarMiniState = val
    },
    setSearchTerm (state, val) {
      state.settings.searchTerm = val
    },
    setStartDate (state, val) {
      state.settings.startDate = val
    },
    setEndDate (state, val) {
      state.settings.endDate = val
    },
    setTypesActive (state, { i, val }) {
      state.settings.types[i].active = val
    },
    setMediaActive (state, { i, val }) {
      state.settings.media[i].active = val
    },
    setWebsiteActive (state, { i, val }) {
      state.settings.websites[i].active = val
    },
    addEngagements (state, { value, min = '0' }) {
      if (value) {
        state.settings.engagements.push({ value, min })
      }
    },
    updateEngagement (state, { previousValue, value, min = '0' }) {
      const index = state.settings.engagements.findIndex(eng => eng.value === previousValue)
      if (index !== -1) {
        state.settings.engagements[index] = {
          ...state.settings.engagements[index],
          value,
          min
        }
      }
    },
    updateEngagementMinMax (state, { i, field, value }) {
      if (field === 'min' || field === 'max') {
        state.settings.engagements[i][field] = value
      }
    },
    removeEngagements (state, i) {
      state.settings.engagements.splice(i, 1)
    },
    addRule (state, rule) {
      state.settings.rules.push(rule)
    },
    removeRule (state, i) {
      state.settings.rules.splice(i, 1)
    },
    setNumberOf (state, val) {
      state.settings.numberOf = Number(val)
    },
    setInterval (state, val) {
      state.settings.interval = val
    },
    setChangepoint (state, val) {
      state.settings.changepoint = val
    },
    setEsQuery (state, val) {
      state.settings.esquery = val
    },
    setHostRegex (state, val) {
      state.settings.hostRegex = val
    },
    setAllSettings (state, val) {
      // in case the url doesn't contain all settings then we need to keep the missing ones
      state.settings = {
        ...state.settings,
        ...val,
      }
    },
    setResults (state, { tool, results }) {
      state.toolResults[tool] = results
    },
    resetResults (state) {
      for (const tool in state.toolResults) {
        state.toolResults[tool] = []
      }
    },
    setError (state, val) {
      state.error = val
    },
    setLastSearchSettings (state, val) {
      state.lastSearchSettings = val
    },
    setSearchHistory (state, val) {
      localStorage.setItem('search-history', JSON.stringify(val))
      state.searchHistory = val
    },
    clearSidebarFilters (state) {
      state.settings = {
        ...state.settings,
        startDate: '',
        endDate: '',
        types: state.settings.types.map(type => ({ ...type, active: false })),
        media: state.settings.media.map(media => ({ ...media, active: false })),
        websites: state.settings.websites.map(website => ({ ...website, active: false })),
        engagements: [],
      }
    },
  },
  actions: {
    loadSettings ({ commit }, val) {
      // WARNING this is dangerous!! since it basically takes input from the user/someone who linked the user the page
      // require guarentees for other getters/ mutations that state.settings is a particular shape
      commit('setAllSettings', val)
      // some components require the variables to be numbers so we put it
      // through the mutations which do that for us
      commit('setNumberOf', val.numberOf)
      // it's a string in the url but we want a boolean
      commit('setChangepoint', val.changepoint === 'true')
      commit('setHostRegex', val.hostRegex === 'true')
    },
    clickSearch ({ state, commit, dispatch }, page) {
      if (validInput(state.settings)) {
        // when making a new search with new settings we need to throw out old results on all tools
        commit('resetResults')
        dispatch('search', page)
      }
    },
    async search ({ state, commit, dispatch }, page) {
      if (!isTool(page)) return

      const queries = buildQueries(state.settings, page)
      commit('setError', null)

      try {
        const results = await Promise.all(
          queries.map(async query => {
            const startTime = Date.now()
            console.info('API', query.name, '-->')

            const token = await auth0.getAccessTokenSilently()

            const response = await fetch(query.url, {
              headers: {
                Authorization: `Bearer ${token}`
              },
            })

            console.info('API', query.name, '<--', `${Math.floor((Date.now() - startTime) / 100) / 10}s`, query.url)

            if (response.status !== 200) { // 200 = OK
              const errJson = await response.json()
              throw errJson?.detail ?? errJson?.error ?? Error('Unknown API error')
            }

            const data = await response.json()

            return {
              ...query,
              data,
            }
          })
        )

        commit('setResults', {
          tool: page,
          results,
        })

        commit('setError', null)
        commit('setLastSearchSettings', cloneDeep(state.settings))
        await updateUrl(state.lastSearchSettings)

        await dispatch('addSearchHistory')
      } catch (err) {
        commit('setError', err)
        console.error(err)
      }
    },
    addSearchHistory ({ state, getters, commit }) {
      let searchHistory = cloneDeep(getters.searchHistory)

      const lastSearch = searchHistory[0]
      const newSearch = {
        searchSettings: cloneDeep(state.settings),
        totalResults: getters.numberOfResults,
        tool: getters.page,
        searchUrl: window.location.href,
      }

      // we only want to add it to history if it's a new search
      if (!isEqual(lastSearch, newSearch)) {
        searchHistory.unshift(newSearch)
      }

      searchHistory = searchHistory.slice(0, 200)

      commit('setSearchHistory', searchHistory)
    },
    // running this function on created() from App.vue
    // the second arg is passed from App.vue. it has more functions than the first arg we were passed here for some reason
    async onCreated ({ state, getters, commit, dispatch }, store) {
      onCreatedLoadUrl({ getters, dispatch })

      onCreatedLoadSearchHistory({ commit })

      onCreatedWatchRoute({ state, getters, dispatch, store })
    }
  }
}

async function onCreatedLoadUrl ({ getters, dispatch }) {
  await router.isReady()
  if (!isTool(getters.page)) return

  // if there are query params in the url when the page is loaded,
  // then grab those and perform a search with them
  const params = router.currentRoute.value.query

  if (!isEmpty(params)) {
    const paramTypes = params.types ? params.types.split(',') : []
    const paramMedia = params.media ? params.media.split(',') : []
    const paramWebsites = (params.websites || GAB).split(',')
    const paramEngagements = params.engagements
      ? params.engagements.split(',').map(eng => {
        const [value, range] = eng.split(':')
        const [min, max] = range ? range.split('-') : ['0', '50']
        return { value, min, max }
      })
      : []

    const fixedTypes = getters.types.map((type) => ({
      ...type,
      active: paramTypes.includes(type.name)
    }))

    const fixedMedia = getters.media.map((media) => ({
      ...media,
      active: paramMedia.includes(media.name)
    }))

    // turn it back to the actual format that we use
    const fixedWebsites = getters.websites.map((website) => ({
      ...website,
      active: paramWebsites.includes(website.name)
    }))

    const fixedEngagements = paramEngagements.map(engagement => {
      return {
        value: engagement.value,
        min: engagement?.min || '0',
        max: engagement?.max || undefined
      }
    })

    await dispatch('loadSettings', {
      ...params,
      types: fixedTypes,
      media: fixedMedia,
      websites: fixedWebsites,
      engagements: fixedEngagements,
    })
  }

  dispatch('search', getters.page)
}

function onCreatedLoadSearchHistory ({ commit }) {
  // things'd be simpler if we could store it only in localStorage, but then things aren't reactive
  commit('setSearchHistory',
    JSON.parse(localStorage.getItem('search-history') ?? '[]')
  )
}

async function onCreatedWatchRoute ({ state, getters, dispatch, store }) {
  await router.isReady()

  store.watch(() => router.currentRoute.value, (newRoute, oldRoute) => {
    // if we e.g. go from Home to Timeline. resurrect the query params
    if (!isTool(oldRoute.name) && isTool(newRoute.name)) {
      updateUrl(state.lastSearchSettings)
    }

    // if we searched on another tool, and we navigate to this tool but the same search hasn't been done here yet.
    if (isTool(getters.page) && isEmpty(getters.results)) {
      dispatch('search', getters.page)
    }
  })
}