<template>
  <div class="input-search-term">
    <template v-if="!small">
      <div class="flex-row-between">
        <div class="flex-row-start">
          <div
            v-for="(filter, index) in filters"
            :key="filter.value"
            class="flex-row-start"
            style="margin-right: var(--spacing-xxl)"
          >
            <RadioButton
              mode="dark"
              v-model="filter.isSelected"
              @update:modelValue="handleRadioChange($event, index)">
              <div class="radio-label">
                {{ filter.label }}
              </div>
              <InfoIcon class="info-icon" />
            </RadioButton>
          </div>
        </div>
        <div class="flex-row-start">
          <GhostButton mode="dark" @click="handleSeeDocs">
            See docs
          </GhostButton>
          <ViewHistory/>
        </div>
      </div>
    </template>
    <div class="search-row">
      <SearchInput
        class="search-bar"
        mode="dark"
        :placeholder="placeholderText"
        :model-value="searchTerm"
        @update:model-value="setSearchTerm"
        @enter="clickSearch($route.name)"
      >
        <EnterArrowIcon class="enter-icon" />
      </SearchInput>
    </div>
  </div>
</template>
<script>
import RadioButton from './RadioButton.vue'
import GhostButton from './GhostButton.vue'
import ViewHistory from './view-history/index.vue'
import InfoIcon from '@/assets/svg/color/info.svg?.component'
import EnterArrowIcon from '@/assets/svg/color/enterArrow.svg?component'

import { createNamespacedHelpers } from 'vuex'
import SearchInput from './SearchInput.vue'
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('searchSettings')

export default {
  data () {
    return {
      filters: [
        { label: 'Basic', value: 'content', isSelected: false },
        { label: 'Boolean', value: 'boolean_content', isSelected: true },
        { label: 'Advanced', value: 'query_string', isSelected: false }
      ]
    }
  },
  components: {
    RadioButton,
    SearchInput,
    GhostButton,
    ViewHistory,
    InfoIcon,
    EnterArrowIcon,
  },
  props: {
    small: Boolean,
  },
  computed: {
    ...mapGetters(['searchTerm', 'esquery']),
    placeholderText () {
      switch (this.esquery) {
        case 'content':
          return 'Example: qanon'
        case 'boolean_content':
          return 'Example: qanon OR wwg1wga'
        case 'query_string':
          return 'Example: (author: anon123 AND text: qanon)'
        default:
          return ''
      }
    }
  },
  methods: {
    ...mapMutations(['setSearchTerm', 'setEsQuery']),
    ...mapActions(['clickSearch']),
    handleSeeDocs () {
      window.open('https://docs.openmeasures.io/docs/guides/public-app#search-term-options', '_blank')
    },
    handleRadioChange (value, index) {
      this.filters.forEach((filter, i) => {
        if (i !== index) {
          filter.isSelected = false
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
.input-search-term {
  color: var(--color-white);
}

.radio-label {
  font-size: var(--fs-2);
  font-weight: 400;
  line-height: var(--fs-5);
  color: var(--color-white);
  margin-left: var(--spacing-md);
  margin-right: var(--spacing-xs);
}

.info-icon {
  width: var(--fs-3);
  height: var(--fs-3);
  color: var(--color-primary-100)
}

.enter-icon {
  width: var(--fs-3);
  height: var(--fs-3);
  color: var(--color-tan-300);
  margin-left: var(--spacing-md);
  cursor: pointer;
}

a {
  color: var(--color-light-grey);
}

p {
  text-align: left;
  font-size: var(--fs-3);
  font-weight: 600;
  margin-bottom: var(--spacing-2);
  font: var(--primary-font);
}

.search-row {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-1);
  margin-top: var(--spacing-3);
}

.search-bar {
  flex: 1;
}
</style>
