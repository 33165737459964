<template>
  <q-td :props="cell" :auto-width="cell.col.autoWidth" class="history-cell">
    <div v-if="cell.col.name === 'searchTerm'">
      {{ cell.value.slice(0, 40) }}{{ cell.value.length > 40 ? '…' : '' }}
    </div>
    <div v-else-if="cell.col.name === 'copy'">
      <q-btn
        round
        flat
        icon="content_copy"
        size="10px"
        @click="copyJson"
      ></q-btn>
      <BaseTooltip v-if="justCopiedJson" always-show>
        Copied!
      </BaseTooltip>
    </div>
    <SourceListCell v-else-if="cell.col.name === 'sources'" :cell="cell"/>
    <DateCell v-else-if="cell.col.name === 'startDate'" :cell="cell"/>
    <DateCell v-else-if="cell.col.name === 'endDate'" :cell="cell"/>
    <div v-else-if="cell.col.name === 'menu'">
      <q-btn round flat icon="more_vert">
        <q-popup-proxy>
          <q-list style="min-width: 100px">
            <a :href="cell.value">
              <q-item clickable v-close-popup>
                <q-item-section>Load Search</q-item-section>
              </q-item>
            </a>
            <q-item clickable v-close-popup @click="copyUrl">
              <q-item-section>Copy Search Url</q-item-section>
            </q-item>
          </q-list>
        </q-popup-proxy>
      </q-btn>
      <BaseTooltip v-if="justCopiedUrl" always-show>
        Copied!
      </BaseTooltip>
    </div>
    <template v-else-if="cell.value">
      {{ cell.value }}
    </template>
  </q-td>
</template>

<script>
import BaseTooltip from '../../BaseTooltip.vue'
import DateCell from '../../DateCell.vue'
import SourceListCell from './SourceListCell.vue'

export default {
  components: {
    BaseTooltip,
    DateCell,
    SourceListCell
  },
  props: {
    cell: Object,
  },
  data () {
    return {
      justCopiedJson: false,
      justCopiedUrl: false,
    }
  },
  methods: {
    copyJson () {
      navigator.clipboard.writeText(JSON.stringify(this.cell.value, null, 2))

      this.justCopiedJson = true
      setTimeout(() => {
        this.justCopiedJson = false
      }, 1000)
    },
    copyUrl () {
      navigator.clipboard.writeText(this.cell.value)

      this.justCopiedUrl = true
      setTimeout(() => {
        this.justCopiedUrl = false
      }, 1000)
    },
  },
}
</script>

<style scoped>
.history-cell {
  color: var(--color-primary-900, #3B1F52);
  font-size: var(--fs-1);
  font-style: normal;
  font-weight: 300;
  line-height: var(--fs-4);
}
</style>