<template>
 <div class="radio-container">
    <label class="radio">
      <input
        type="radio"
        :checked="modelValue"
        @change="$emit('update:modelValue', $event.target.checked)"
      >
      <span
        :class="`radio-custom ${mode}-mode`"
      />
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'light',
      validator: value => ['light', 'dark'].includes(value)
    }
  },
  emits: ['update:modelValue']
}
</script>

<style scoped>
.radio-container {
  display: inline-flex;
  align-items: center;
}

.radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-custom {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px;
  border: 1px solid;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.light-mode {
  border-color: var(--color-tan-800);
  box-shadow: 0.5px 0.5px 0px 0px var(--color-tan-300);
}

.light-mode:hover {
  border-color: var(--color-primary-700);
}

.dark-mode {
  border-color: var(--color-tan-100);
  box-shadow: 0.5px 0.5px 0px 0px var(--color-primary-800);
}

.dark-mode:hover {
  border-color: var(--color-primary-100);
}

.radio input:checked + .radio-custom.light-mode  {
  border-color: var(--color-primary-600);
  background-color: transparent;
  box-shadow: 0.5px 0.5px 0px 0px var(--color-primary-100);
}

.radio input:checked + .radio-custom.light-mode:hover  {
  border-color: var(--color-primary-700);
}

.radio input:checked + .radio-custom.dark-mode  {
  border-color: var(--color-primary-300);
  background-color: transparent;
}

.radio input:checked + .radio-custom.dark-mode:hover  {
  border-color: var(--color-primary-100);
}

.radio input:checked + .radio-custom::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.radio input:checked + .radio-custom.light-mode::after {
  background-color: var(--color-primary-600);
}

.radio input:checked + .radio-custom.dark-mode::after {
  background-color: var(--color-primary-300);
}

.radio input:checked + .radio-custom.light-mode:hover::after {
  background-color: var(--color-primary-700);
}

.radio input:checked + .radio-custom.dark-mode:hover::after {
  background-color: var(--color-primary-100);
}
</style>