<template>
  <div>
    <div
      v-if="cell.thumbnail_url"
      :class="['media-thumbnail', { blur: blurThumbnails }]"
      :style="{ 'background-image': `url(${cell.thumbnail_url})` }"
      @click="mediaModal = true"
    />
    <MediaModal
      v-model="mediaModal"
      :mimetype="cell.mimetype"
      :media-url="cell.media_url"
      :cell="cell"
    />
  </div>
</template>

<script>
import MediaModal from './MediaModal.vue'

export default {
  components: {
    MediaModal,
  },
  props: {
    cell: Object,
    blurThumbnails: Boolean,
  },
  data () {
    return {
      mediaModal: false,
    }
  },
}
</script>

<style scoped>
.media-thumbnail {
  width: 80px;
  height: 48px;
  background-size: cover;
  cursor: pointer;
}

.blur {
  filter: blur(5px);
}
</style>